export const MATTER_DATA: any[] = [
  {
    "Plant Genus*": "Avena",
    "Plant Species": "",
    "Material image": "https://images.squarespace-cdn.com/content/v1/64022ce3cc42ea2ee80f0425/717d76c0-c86c-4a6d-b16c-d7a759a53809/TIP_Bi0n_kubbhus_cordwood_innovation_testba%CC%88dd_experiment_a%CC%8Aterbruk.jpg?format=2500w",
    "Material Name*": "Straw Mortar",
    "Project Title*": "Eko-odlarnas butik",
    "Project Author*": "TiP Architects",
    "About Project": "Floors and walls were built using cornwood technology, a masonry technique where bricks are replaced with wood. The mortar was mixed on site, from clay, sand and straw.",
    "Project Link*": "https://www.theoryintopractice.se/eko-odlarna-kubbhus-terbruk",
    "Type*": "walls",
    "Processing": "dry",
    "Function*": "reinforcement",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Betula",
    "Plant Species": "",
    "Material image": "https://images.squarespace-cdn.com/content/v1/64022ce3cc42ea2ee80f0425/717d76c0-c86c-4a6d-b16c-d7a759a53809/TIP_Bi0n_kubbhus_cordwood_innovation_testba%CC%88dd_experiment_a%CC%8Aterbruk.jpg?format=2500w",
    "Material Name*": "Birch Cordwood",
    "Project Title*": "Eko-odlarnas butik",
    "Project Author*": "TiP Architects",
    "About Project": "Floors and walls were built using cornwood technology, a masonry technique where bricks are replaced with wood. The mortar was mixed on site, from clay, sand and straw.",
    "Project Link*": "https://www.theoryintopractice.se/eko-odlarna-kubbhus-terbruk",
    "Type*": "walls",
    "Processing": "dry",
    "Function*": "reinforcement",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Betula",
    "Plant Species": "",
    "Material image": "https://kubbhus.se/____impro/1/onewebmedia/Umasjo%CC%882Fasad.jpg?etag=%22W%2F%22%20%22edb6-548731e0%22&sourceContentType=image%2Fjpeg&ignoreAspectRatio&resize=424%2B291&extract=0%2B0%2B423%2B290&quality=85",
    "Material Name*": "Birch Cordwood",
    "Project Title*": "Sveriges nordligaste kubbhus?",
    "Project Author*": "Olle Hagman",
    "About Project": "In Umasjö, Tärnaby, there is an unusual house, built from logs by Fredrik Stenmark in the 1880s. The outer walls are made up of round logs of mountain birch, which have been piled as in a wood stack, with layers of clay in between so that the cavities are filled. Between the rows of logs are longitudinal ribs as a connection. The outsides of the walls are clad with clapboard panels and the insides are covered with mud. The house was used until the 1940s, i.a. there was a cafe here when the highway was built in the 1930s.",
    "Project Link*": "https://kubbhus.se/exempel/aeldre%20svenska%20kubbhus/umasjoe",
    "Type*": "walls",
    "Processing": "dry",
    "Function*": "structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Cocos",
    "Plant Species": "",
    "Material image": "https://freight.cargo.site/w/2000/q/75/i/64fe92654f0e35bc41812be6ed6cf7f3be21d75ec77977c0e8f0ce34f2206eda/DSCF1640.JPG",
    "Material Name*": "Coconut Cordage",
    "Project Title*": "Harvest to House",
    "Project Author*": "Djernes & Bell",
    "About Project": "Thatrch being sewn onto the Dinesen x KADK residency with coconut cordage",
    "Project Link*": "https://harvesttohouse.com/Harvest-To-House",
    "Type*": "details and paint",
    "Processing": "braid",
    "Function*": "",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Corylus",
    "Plant Species": "",
    "Material image": "https://freight.cargo.site/w/2000/q/75/i/2401295ce4989aaf3bac16a3d944bd6493ca0aab967b37a699a8bd3d953d8f9a/IMG_3699.JPG",
    "Material Name*": "Hazel Spars",
    "Project Title*": "Harvest to House",
    "Project Author*": "Djernes & Bell",
    "About Project": "Local Works Studio teaching green woodworking techniques and traditional hazel spar-making with locally harvested materials. See film at https://djernesbell.com/Harvest-to-House-Film",
    "Project Link*": "https://harvesttohouse.com/Harvest-To-House",
    "Type*": "walls",
    "Processing": "dry",
    "Function*": "",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Corylus",
    "Plant Species": "",
    "Material image": "https://localworksstudio.com/wp-content/uploads/2023/05/IMG_0300-copy.jpeg",
    "Material Name*": "Hazel Rods",
    "Project Title*": "The Nurture Garden at RHS Chelsea Flower Show for Sarah Price Landscapes.",
    "Project Author*": "Studio Local Works",
    "About Project": "Large sections of the boundary walls were made with straw bales, fixed in place with Hazel rods, with lime render applied to their surface.",
    "Project Link*": "https://localworksstudio.com/projects/chelsea-flower-show-2023/",
    "Type*": "walls",
    "Processing": "dry",
    "Function*": "structure",
    "Comment": "The hazel rods are completly locked into the walls and cannot be reached.",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Hordeum",
    "Plant Species": "",
    "Material image": "https://images.squarespace-cdn.com/content/v1/64022ce3cc42ea2ee80f0425/717d76c0-c86c-4a6d-b16c-d7a759a53809/TIP_Bi0n_kubbhus_cordwood_innovation_testba%CC%88dd_experiment_a%CC%8Aterbruk.jpg?format=2500w",
    "Material Name*": "Straw Mortar",
    "Project Title*": "Eko-odlarnas butik",
    "Project Author*": "TiP Architects",
    "About Project": "Floors and walls were built using cornwood technology, a masonry technique where bricks are replaced with wood. The mortar was mixed on site, from clay, sand and straw.",
    "Project Link*": "https://www.theoryintopractice.se/eko-odlarna-kubbhus-terbruk",
    "Type*": "walls",
    "Processing": "dry",
    "Function*": "reinforcement",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Hordeum",
    "Plant Species": "",
    "Material image": "https://images.squarespace-cdn.com/content/v1/64022ce3cc42ea2ee80f0425/717d76c0-c86c-4a6d-b16c-d7a759a53809/TIP_Bi0n_kubbhus_cordwood_innovation_testba%CC%88dd_experiment_a%CC%8Aterbruk.jpg?format=2500w",
    "Material Name*": "Straw Mortar",
    "Project Title*": "Eko-odlarnas butik",
    "Project Author*": "TiP Architects",
    "About Project": "Floors and walls were built using cornwood technology, a masonry technique where bricks are replaced with wood. The mortar was mixed on site, from clay, sand and straw.",
    "Project Link*": "https://www.theoryintopractice.se/eko-odlarna-kubbhus-terbruk",
    "Type*": "walls",
    "Processing": "mix",
    "Function*": "reinforcement",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Humulus",
    "Plant Species": "",
    "Material image": "https://localworksstudio.com/wp-content/uploads/2023/05/LWS_15-copy.jpeg",
    "Material Name*": "Hop Twines",
    "Project Title*": "The Nurture Garden at RHS Chelsea Flower Show for Sarah Price Landscapes.",
    "Project Author*": "Studio Local Works",
    "About Project": "After retting the hops in the field to break down the tough stem material, we were able to turn the fibrous bines and twine into 50 meters of cordage for the garden.",
    "Project Link*": "https://localworksstudio.com/projects/chelsea-flower-show-2023/",
    "Type*": "details and paint",
    "Processing": "braid",
    "Function*": "structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Phragmites",
    "Plant Species": "",
    "Material image": "https://freight.cargo.site/w/2000/q/75/i/2a8fcd560de523d52a2cc9d7c891da1a5854f0017b8bbf14bdb2c5d3b370b782/IMG_3682.JPG",
    "Material Name*": "Thatching Reed",
    "Project Title*": "Harvest to House",
    "Project Author*": "Djernes & Bell",
    "About Project": "Thatrch being sewn onto the Dinesen x KADK residency with coconut cordage",
    "Project Link*": "https://harvesttohouse.com/Harvest-To-House",
    "Type*": "roof",
    "Processing": "dry",
    "Function*": "",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Picea",
    "Plant Species": "",
    "Material image": "https://kubbhus.se/____impro/1/onewebmedia/Vallstugan_8Tibro_BZ.jpg?etag=%22W%2F%22%20%2271f14-5485bc30%22&sourceContentType=image%2Fjpeg&ignoreAspectRatio&resize=600%2B454&extract=0%2B0%2B598%2B454&quality=85",
    "Material Name*": "Fir Cordwood",
    "Project Title*": "Vallstugan, Tibro",
    "Project Author*": "Olle Hagman",
    "About Project": "The house was most likely built in the late 1870s. It must have been built by a soldier's son named Vall. The house has a frame of standing posts, 130 x 130 mm. On the ground floor, the space between the posts has been filled with blocks, both in the outer walls and in the partition between the two chambers. The cornwood consists of split wood of spruce and fir, mostly rather thin wood and relatively densely grown. The cube is unusually short, 130 mm.",
    "Project Link*": "https://kubbhus.se/exempel/aeldre%20svenska%20kubbhus/vallstugan",
    "Type*": "walls",
    "Processing": "dry",
    "Function*": "structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Picea",
    "Plant Species": "",
    "Material image": "https://kubbhus.se/____impro/1/onewebmedia/BF_TorslundaMurning.jpg?etag=%22W%2F%22%20%22334f7-54872339%22&sourceContentType=image%2Fjpeg&ignoreAspectRatio&resize=500%2B737&extract=0%2B0%2B499%2B737&quality=85",
    "Material Name*": "Fir Cordwood",
    "Project Title*": "Borgen, Torslunda, Täby",
    "Project Author*": "Olle Hagman",
    "About Project": "In Torslunda, Täby, there is a cornwood house that was used as a back cabin, among other things. The house was built around 1870 and has external dimensions of 6.25x3.65 m. It is built with a frame of hewn logs and blocks 15-16 cm long. The cube consists of a mixture of pine and spruce.",
    "Project Link*": "https://kubbhus.se/exempel/aeldre%20svenska%20kubbhus/borgen",
    "Type*": "walls",
    "Processing": "dry",
    "Function*": "structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Picea",
    "Plant Species": "",
    "Material image": "https://kubbhus.se/____impro/1/onewebmedia/BildSkrivstuganSydvast.jpg?etag=%221cb1c-547f2382%22&sourceContentType=image%2Fjpeg",
    "Material Name*": "Fir Wood and Cordwood",
    "Project Title*": "Min Skrivarstuga",
    "Project Author*": "Olle Hagman",
    "About Project": "My own little cornwood house measures about 2.6 x 2.6 m. The dimensions were determined by the fact that I wanted to use the foundation stones from an old barn that once stood there. It is built with a rough framework of fir logs joined together with knots and tenons. The logs are about 17 cm long and mainly made of spruce, mixed with a little aspen. I have carved some blocks into figures: a car, a heart, a fish, three stars, etc. Some stick out, to hang clothes on and to put books on. Here and there I have inserted glass bottles in the walls to let in light.",
    "Project Link*": "https://kubbhus.se/exempel/nya%20svenska%20kubbhus/min%20skrivstuga",
    "Type*": "walls",
    "Processing": "dry",
    "Function*": "structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Pinus",
    "Plant Species": "",
    "Material image": "https://kubbhus.se/____impro/1/onewebmedia/Kilsby_kubbhus_Va%CC%88stfasad_CIMG6533.jpg?etag=%2210bb0-57cd330c%22&sourceContentType=image%2Fjpeg&ignoreAspectRatio&resize=640%2B480&extract=0%2B26%2B640%2B407&quality=85",
    "Material Name*": "Pine Roofing",
    "Project Title*": "Svinhus av kubb",
    "Project Author*": "Olle Hagman",
    "About Project": "On Kilsby farm in south-eastern Värmland there is a large old log pig house. Despite a rather large need for renovation, you can clearly see that the house is extremely well built. It has a foundation of large, hewn boulders. The walls are thick and plastered, made of used timber. In the roof are long, hand-hewn rafters, cut from straight furrows.",
    "Project Link*": "https://kubbhus.se/exempel/aeldre%20svenska%20kubbhus/kilsby",
    "Type*": "walls, roof",
    "Processing": "dry",
    "Function*": "structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Pinus",
    "Plant Species": "",
    "Material image": "https://kubbhus.se/____impro/1/onewebmedia/Vallstugan_8Tibro_BZ.jpg?etag=%22W%2F%22%20%2271f14-5485bc30%22&sourceContentType=image%2Fjpeg&ignoreAspectRatio&resize=600%2B454&extract=0%2B0%2B598%2B454&quality=85",
    "Material Name*": "Pine Cordwood",
    "Project Title*": "Vallstugan, Tibro",
    "Project Author*": "Olle Hagman",
    "About Project": "Huset är med största sannolikhet byggt under sent 1870-tal. Det skall ha byggts av en soldatson vid namn Vall.  Huset har en stomme av stående stolpar, 130 x 130 mm. I bottenvåningen har utrymmet mellan stolparna fyllts med kubb, både i ytterväggarna och i mellanväggen mellan de båda kamrarna. Kubben består av kluvet virke av gran och fur, mestadels ganska klent virke och relativt tätvuxet. Kubben är ovanligt kort, 130 mm.",
    "Project Link*": "https://kubbhus.se/exempel/aeldre%20svenska%20kubbhus/vallstugan",
    "Type*": "walls",
    "Processing": "dry",
    "Function*": "structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Pinus",
    "Plant Species": "",
    "Material image": "https://kubbhus.se/____impro/1/onewebmedia/BF_TorslundaMurning.jpg?etag=%22W%2F%22%20%22334f7-54872339%22&sourceContentType=image%2Fjpeg&ignoreAspectRatio&resize=500%2B737&extract=0%2B0%2B499%2B737&quality=85",
    "Material Name*": "Pine Cordwood",
    "Project Title*": "Borgen, Torslunda, Täby",
    "Project Author*": "Olle Hagman",
    "About Project": "In Torslunda, Täby, there is a cornwood house that was used as a back cabin, among other things. The house was built around 1870 and has external dimensions of 6.25x3.65 m. It is built with a frame of hewn logs and blocks 15-16 cm long. The cube consists of a mixture of pine and spruce.",
    "Project Link*": "https://kubbhus.se/exempel/aeldre%20svenska%20kubbhus/borgen",
    "Type*": "walls",
    "Processing": "dry",
    "Function*": "structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Populus",
    "Plant Species": "",
    "Material image": "https://kubbhus.se/____impro/1/onewebmedia/BildSkrivstuganSydvast.jpg?etag=%221cb1c-547f2382%22&sourceContentType=image%2Fjpeg",
    "Material Name*": "Aspen Cordwood",
    "Project Title*": "Min Skrivarstuga",
    "Project Author*": "Olle Hagman",
    "About Project": "My own little cornwood house measures about 2.6 x 2.6 m. The dimensions were determined by the fact that I wanted to use the foundation stones from an old barn that once stood there. It is built with a rough framework of fir logs joined together with knots and tenons. The logs are about 17 cm long and mainly made of spruce, mixed with a little aspen. I have carved some blocks into figures: a car, a heart, a fish, three stars, etc. Some stick out, to hang clothes on and to put books on. Here and there I have inserted glass bottles in the walls to let in light.",
    "Project Link*": "https://kubbhus.se/exempel/nya%20svenska%20kubbhus/min%20skrivstuga",
    "Type*": "walls",
    "Processing": "dry",
    "Function*": "structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Quercus",
    "Plant Species": "",
    "Material image": "https://images.squarespace-cdn.com/content/v1/64022ce3cc42ea2ee80f0425/717d76c0-c86c-4a6d-b16c-d7a759a53809/TIP_Bi0n_kubbhus_cordwood_innovation_testba%CC%88dd_experiment_a%CC%8Aterbruk.jpg?format=2500w",
    "Material Name*": "Oak Cordwood",
    "Project Title*": "Eko-odlarnas butik",
    "Project Author*": "TiP Architects",
    "About Project": "Floors and walls were built using cornwood technology, a masonry technique where bricks are replaced with wood. The mortar was mixed on site, from clay, sand and straw.",
    "Project Link*": "https://www.theoryintopractice.se/eko-odlarna-kubbhus-terbruk",
    "Type*": "walls",
    "Processing": "dry",
    "Function*": "reinforcement",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Rubus",
    "Plant Species": "",
    "Material image": "https://localworksstudio.com/wp-content/uploads/2023/05/IMG_0430.jpg",
    "Material Name*": "Bramble Fibres and Twine",
    "Project Title*": "The Nurture Garden at RHS Chelsea Flower Show for Sarah Price Landscapes.",
    "Project Author*": "Studio Local Works",
    "About Project": "Bramble fibres and twine used as seizing to fix the cordage to the posts\r\n",
    "Project Link*": "https://localworksstudio.com/projects/chelsea-flower-show-2023/",
    "Type*": "details and paint",
    "Processing": "braid",
    "Function*": "structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Salix",
    "Plant Species": "",
    "Material image": "https://notvab.se/wp-content/uploads/2017/06/IMG_8421.jpg",
    "Material Name*": "Braided Salix",
    "Project Title*": "Faunadepå",
    "Project Author*": "Natur och trädgårdsvård",
    "About Project": "In the picture we see Safija and the children from the meeting place Gnistan in Ekostaden, Augustenborg who have woven this fence from willow. Here, you put in the organic material from the area, such as sticks, leaves, branches, etc. The hedge provides space for the organic material, which in turn becomes food and habitat for, among other things. small bugs, insects, caterpillars, hedgehogs and bats. During the time that the organic material is broken down by microorganisms and fungi, if you stop and observe, you can see gnawing marks, passages that the animals create, perhaps you see a bird in this new ecosystem.",
    "Project Link*": "https://notvab.se/2017/07/faunadepa/",
    "Type*": "details and paint",
    "Processing": "braid",
    "Function*": "structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Salix",
    "Plant Species": "",
    "Material image": "https://www.karinfrankenstein.se/wp-content/uploads/web_insekt1-1.jpg",
    "Material Name*": "Salix Sculpture",
    "Project Title*": "Insekternas kollektivbostad",
    "Project Author*": "Karin Auran Frankenstein",
    "About Project": "Sculptures of Salix, ceramics, natural stone, sold to MKB via Notvab, in collaboration with Tomas Auran.\r\nBuilt in 2017 on an open yard belonging to an apartment building in north of Sorgenfri, Malmö\r\n\r\nThe work “The collective home of the insects” is a functionalist sculpture with the purpose to contribute to physical change in the natural cycle, created on the basis of the importance of biodiversity even in urban environments and the relevance of the pollinating insects for a functioning ecosystem. We identified two common deficiencies for the pollinators in the typical urban environment. Partly homes, where parks and other green areas in the city are kept free of old dead plants and tree parts that otherwise form these and partly food early in the spring.",
    "Project Link*": "https://www.karinfrankenstein.se/insekternas-kollektivbostad-i-sorgenfri/",
    "Type*": "details and paint",
    "Processing": "dry",
    "Function*": "structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Salix",
    "Plant Species": "",
    "Material image": "https://www.karinfrankenstein.se/wp-content/uploads/insektst_salix2_web.jpg",
    "Material Name*": "Salix Furniture",
    "Project Title*": "Slottsträdgården",
    "Project Author*": "Karin Auran Frankenstein, Tomas Auran",
    "About Project": "“Slottsträdgården” in collaboration with John Taylor and Linnea Dickson where we created a concept garden with specific flowers and furniture of living Salix. The salix blooms very early in the spring and has a high protein content in its pollen and therefore got a central place in the garden.",
    "Project Link*": "https://www.karinfrankenstein.se/insekternas-kollektivbostad/",
    "Type*": "details and paint, furniture",
    "Processing": "braid",
    "Function*": "structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Salix",
    "Plant Species": "",
    "Material image": "https://www.lhi.is/sites/default/files/thumbnails/image/willow5.jpg",
    "Material Name*": "Willow String, Paint and Water Filters",
    "Project Title*": "Willow Project",
    "Project Author*": "Students at Iceland Academy of Arts",
    "About Project": "By only adding water and heat a group of icelandic students examined Willow as material.",
    "Project Link*": "https://www.lhi.is/en/node/11568",
    "Type*": "details and paint",
    "Processing": "braid, burn",
    "Function*": "decor, paint",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Secale",
    "Plant Species": "",
    "Material image": "https://images.squarespace-cdn.com/content/v1/64022ce3cc42ea2ee80f0425/717d76c0-c86c-4a6d-b16c-d7a759a53809/TIP_Bi0n_kubbhus_cordwood_innovation_testba%CC%88dd_experiment_a%CC%8Aterbruk.jpg?format=2500w",
    "Material Name*": "Straw Mortar",
    "Project Title*": "Eko-odlarnas butik",
    "Project Author*": "TiP Architects",
    "About Project": "Floors and walls were built using cornwood technology, a masonry technique where bricks are replaced with wood. The mortar was mixed on site, from clay, sand and straw.",
    "Project Link*": "https://www.theoryintopractice.se/eko-odlarna-kubbhus-terbruk",
    "Type*": "walls",
    "Processing": "mix",
    "Function*": "reinforcement",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Secale",
    "Plant Species": "",
    "Material image": "https://images.squarespace-cdn.com/content/v1/64022ce3cc42ea2ee80f0425/717d76c0-c86c-4a6d-b16c-d7a759a53809/TIP_Bi0n_kubbhus_cordwood_innovation_testba%CC%88dd_experiment_a%CC%8Aterbruk.jpg?format=2500w",
    "Material Name*": "Straw Mortar",
    "Project Title*": "Eko-odlarnas butik",
    "Project Author*": "TiP Architects",
    "About Project": "Floors and walls were built using cornwood technology, a masonry technique where bricks are replaced with wood. The mortar was mixed on site, from clay, sand and straw.",
    "Project Link*": "https://www.theoryintopractice.se/eko-odlarna-kubbhus-terbruk",
    "Type*": "walls",
    "Processing": "mix",
    "Function*": "reinforcement",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Thuja",
    "Plant Species": "",
    "Material image": "https://kubbhus.se/____impro/1/onewebmedia/KeweenawCordwoodCabin.jpg?etag=%22W%2F%22%20%2298f2-54871186%22&sourceContentType=image%2Fjpeg&ignoreAspectRatio&resize=300%2B226&extract=50%2B38%2B198%2B149&quality=85",
    "Material Name*": "White Cedar wood",
    "Project Title*": "Kubbhus att hyra, i Michigan, USA",
    "Project Author*": "Olle Hagman",
    "About Project": "On the shores of the Keweenaw Waterway, in the state of Michigan in the United States of America, where there are also several old log cabins, there is also a newly built house that you can rent. The house is about 60 square meters, with a sleeping loft above the inner part and full ceiling height up to the ridge in the living room. The logs are made of white cedar, a type of wood that we unfortunately do not have access to in Sweden. The house was built in 2004-2005 by Professor Bruce Barna and his \"all-girl crew\", an all-female work team, led by his daughter Nicole.",
    "Project Link*": "https://kubbhus.se/exempel/resten%20av%20vaerlden/att%20hyra%20i%20usa",
    "Type*": "walls",
    "Processing": "dry",
    "Function*": "structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Triticum",
    "Plant Species": "",
    "Material image": "https://images.squarespace-cdn.com/content/v1/64022ce3cc42ea2ee80f0425/717d76c0-c86c-4a6d-b16c-d7a759a53809/TIP_Bi0n_kubbhus_cordwood_innovation_testba%CC%88dd_experiment_a%CC%8Aterbruk.jpg?format=2500w",
    "Material Name*": "Straw Mortar",
    "Project Title*": "Eko-odlarnas butik",
    "Project Author*": "TiP Architects",
    "About Project": "Floors and walls were built using cornwood technology, a masonry technique where bricks are replaced with wood. The mortar was mixed on site, from clay, sand and straw.",
    "Project Link*": "https://www.theoryintopractice.se/eko-odlarna-kubbhus-terbruk",
    "Type*": "walls",
    "Processing": "mix",
    "Function*": "reinforcement",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Typha",
    "Plant Species": "",
    "Material image": "https://freight.cargo.site/w/1500/q/75/i/aeec467e018cee4137a90240606fee41532fc7e3c959f0350beb2f5eff4484f3/DSCF1890.JPG",
    "Material Name*": "Bulrush Plaster",
    "Project Title*": "Site Based Materials, Hedeskov 2022",
    "Project Author*": "Djernes&Bell",
    "About Project": "Together with our client Hedeskov, we have invited Local Works Studio to carry out a landscape audit and material design for site based clay plaster, clay flooring and other bio and plant based materials for renovation of this historic rural school building. The samples are showing site-based clay plaster samples, sing site excavation clay, iron-rich sand and plant fibre from bulrushes harvested by the client on the site.",
    "Project Link*": "https://djernesbell.com/11-22-Site-Based-Materials-Hedeskov",
    "Type*": "walls",
    "Processing": "mix",
    "Function*": "plaster",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Linum",
    "Plant Species": "",
    "Material image": "https://freight.cargo.site/w/1500/q/94/i/5117991929a32f346a6c4c730d4aacd2fd4d3ac520b2076fec3b40b9a6feb0b3/StoelenOpGrijsBewerking_60484-3_25.1-stoel_web.jpg",
    "Material Name*": "Flax fibre",
    "Project Title*": "FLAX Chair",
    "Project Author*": "Christien Meindertsma",
    "About Project": "By using long and short Flax fibres combined with  polylactic (PLA, from sugar cane or corn starch) in a mold and heat-pressed, C.M made a fully biodegradable chair.",
    "Project Link*": "https://christienmeindertsma.com/Flax-Chair",
    "Type*": "furniture",
    "Processing": "mix",
    "Function*": "structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Picea",
    "Plant Species": "",
    "Material image": "https://i0.wp.com/granbyworkshop.co.uk/wp-content/uploads/2021/12/granby-workshop-about-products.jpg?w=1244&ssl=1",
    "Material Name*": "Sawdust",
    "Project Title*": "Smoked Cheramics",
    "Project Author*": "Grnaby Workshop",
    "About Project": "Using sawdust and other materials to develope a distinctive model of designer- led manufacturing – operating at a scale that allows for high quality products to be made, whilst retaining variety in their design, so that each product is different.",
    "Project Link*": "https://granbyworkshop.co.uk/about/background/",
    "Type*": "details and paint, furniture",
    "Processing": "dry, soak",
    "Function*": "decor, structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Thuja",
    "Plant Species": "",
    "Material image": "https://i0.wp.com/granbyworkshop.co.uk/wp-content/uploads/2021/12/granby-workshop-about-products.jpg?w=1244&ssl=1",
    "Material Name*": "Sawdust",
    "Project Title*": "Smoked Cheramics",
    "Project Author*": "Grnaby Workshop",
    "About Project": "Using sawdust and other materials to develope a distinctive model of designer- led manufacturing – operating at a scale that allows for high quality products to be made, whilst retaining variety in their design, so that each product is different.",
    "Project Link*": "https://granbyworkshop.co.uk/about/background/",
    "Type*": "details and paint, furniture",
    "Processing": "dry, soak",
    "Function*": "decor, structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Quercus",
    "Plant Species": "",
    "Material image": "https://i0.wp.com/granbyworkshop.co.uk/wp-content/uploads/2021/12/granby-workshop-about-products.jpg?w=1244&ssl=1",
    "Material Name*": "Sawdust",
    "Project Title*": "Smoked Cheramics",
    "Project Author*": "Grnaby Workshop",
    "About Project": "Using sawdust and other materials to develope a distinctive model of designer- led manufacturing – operating at a scale that allows for high quality products to be made, whilst retaining variety in their design, so that each product is different.",
    "Project Link*": "https://granbyworkshop.co.uk/about/background/",
    "Type*": "details and paint, furniture",
    "Processing": "dry, soak",
    "Function*": "decor, structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Pinus",
    "Plant Species": "",
    "Material image": "https://i0.wp.com/granbyworkshop.co.uk/wp-content/uploads/2021/12/granby-workshop-about-products.jpg?w=1244&ssl=1",
    "Material Name*": "Sawdust",
    "Project Title*": "Smoked Cheramics",
    "Project Author*": "Grnaby Workshop",
    "About Project": "Using sawdust and other materials to develope a distinctive model of designer- led manufacturing – operating at a scale that allows for high quality products to be made, whilst retaining variety in their design, so that each product is different.",
    "Project Link*": "https://granbyworkshop.co.uk/about/background/",
    "Type*": "details and paint, furniture",
    "Processing": "dry, soak",
    "Function*": "decor, structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Populus",
    "Plant Species": "",
    "Material image": "https://i0.wp.com/granbyworkshop.co.uk/wp-content/uploads/2021/12/granby-workshop-about-products.jpg?w=1244&ssl=1",
    "Material Name*": "Sawdust",
    "Project Title*": "Smoked Cheramics",
    "Project Author*": "Grnaby Workshop",
    "About Project": "Using sawdust and other materials to develope a distinctive model of designer- led manufacturing – operating at a scale that allows for high quality products to be made, whilst retaining variety in their design, so that each product is different.",
    "Project Link*": "https://granbyworkshop.co.uk/about/background/",
    "Type*": "details and paint, furniture",
    "Processing": "dry, soak",
    "Function*": "decor, structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Zea mays",
    "Plant Species": "",
    "Material image": "https://www.fernandolaposse.com/wp-content/uploads/2015/12/lucy-with-harvest-and-colored-leaves.jpg",
    "Material Name*": "Corn Husks",
    "Project Title*": "Totomoxtle",
    "Project Author*": "Fernando Laposse",
    "About Project": "Totomoxtle is a new veneer material made with husks of heirloom Mexican corn. Ranging from deep purples, to soft creams, Totomoxtle showcases the wealth of diversity of the native corns of Mexico which are naturally colourful and are essential for the rich gastronomy of the country.",
    "Project Link*": "https://www.fernandolaposse.com/projects/totomoxtle/",
    "Type*": "furniture, details and paint",
    "Processing": "dry",
    "Function*": "decor",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Pinus",
    "Plant Species": "",
    "Material image": "http://tamaraorjola.com/____impro/1/onewebmedia/Tamara%20Orjola_pinetrees2.jpg?etag=W%2F%222afa67-581cf915%22&sourceContentType=image%2Fjpeg&ignoreAspectRatio&resize=1011%2B1432&extract=0%2B0%2B1008%2B1432&quality=85",
    "Material Name*": "Pine Needle Fibres",
    "Project Title*": "Forest Wool",
    "Project Author*": "Tamara Orjola",
    "About Project": "Pine trees are the world’s main source of timber. Every year 600 million pine trees are cut down in the EU only. But there is more to the tree than just wood: pine needles account for 20 to 30 percent of its mass.\r\n\r\nTamara Orjola researched the potential use of the billions of needles that go unused and found them to be a great alternative for all kinds of fibers. ",
    "Project Link*": "http://tamaraorjola.com/index.html",
    "Type*": "furniture",
    "Processing": "soak",
    "Function*": "structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Lupinus",
    "Plant Species": "",
    "Material image": "https://cortex.persona.co/w/600/q/94/i/b07be6072100e0aa0362f74fd433db657e37a54ff4021a7f3e47a83cec7ad28d/06-landslag-1.gif",
    "Material Name*": "Lupin Fibre Boards",
    "Project Title*": "Lupine Project",
    "Project Author*": "Efnasmiðjan ehf.\r\nInga Kristín Guðlaugsdóttir and\r\nElín S. Harðardóttir",
    "About Project": "The lupine is an interesting plant for its ability to produce nitrogen, it provides nutrition for itself as well as other plants and is sustainable. Its construction consists of protein, ash, minerals, and cellulose, with lignin as the main cellulose. We found it interesting to investigate the lupine, to do a material research on its potentials for raw material. Try to produce a biodegradable product without any additives. Succeeding this task, could give the lupine a second opportunity on a new basis.",
    "Project Link*": "https://www.lupineproject.com/",
    "Type*": "details and paint, furniture",
    "Processing": "dry, soak",
    "Function*": "structure, details and paint",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Fagus",
    "Plant Species": "",
    "Material image": "https://www.mareldlandskap.se/wp-content/uploads/2020/09/DSCF2870-HDR-scaled.jpg",
    "Material Name*": "Recycled wood",
    "Project Title*": "Hoppet",
    "Project Author*": "Mareld Landskapsarkitekter",
    "About Project": "The preschool “Hoppet” is intended to serve as a pilot project for the City of Gothenburg with the ambitions to reach Zero CO2 emissions and as small imprint on the environment as possible. This is made by using fossil-free materials, minimal additions and reuse of materials in production and later on in management. The plot for the preschool is in a beautiful nature area in Backa.\r\n\r\nMARELD got the opportunity to design and produce construction drawings of the school yard. The design brings out the quality of the existing nature on the plot. There are different zones with more calm rooms for the small children as well as challenging areas for running and exploring. The design of the yard is made to the biggest part of recycled materials as well as located produced wood.",
    "Project Link*": "https://www.mareldlandskap.se/project/hoppet-preschool/",
    "Type*": "furniture",
    "Processing": "dry",
    "Function*": "structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Pinus",
    "Plant Species": "",
    "Material image": "https://www.mareldlandskap.se/wp-content/uploads/2020/09/DSCF2870-HDR-scaled.jpg",
    "Material Name*": "Recycled wood",
    "Project Title*": "Hoppet",
    "Project Author*": "Mareld Landskapsarkitekter",
    "About Project": "The preschool “Hoppet” is intended to serve as a pilot project for the City of Gothenburg with the ambitions to reach Zero CO2 emissions and as small imprint on the environment as possible. This is made by using fossil-free materials, minimal additions and reuse of materials in production and later on in management. The plot for the preschool is in a beautiful nature area in Backa.\r\n\r\nMARELD got the opportunity to design and produce construction drawings of the school yard. The design brings out the quality of the existing nature on the plot. There are different zones with more calm rooms for the small children as well as challenging areas for running and exploring. The design of the yard is made to the biggest part of recycled materials as well as located produced wood.",
    "Project Link*": "https://www.mareldlandskap.se/project/hoppet-preschool/",
    "Type*": "furniture",
    "Processing": "dry",
    "Function*": "structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Betula",
    "Plant Species": "",
    "Material image": "https://www.mareldlandskap.se/wp-content/uploads/2020/09/DSCF2870-HDR-scaled.jpg",
    "Material Name*": "Recycled wood",
    "Project Title*": "Hoppet",
    "Project Author*": "Mareld Landskapsarkitekter",
    "About Project": "The preschool “Hoppet” is intended to serve as a pilot project for the City of Gothenburg with the ambitions to reach Zero CO2 emissions and as small imprint on the environment as possible. This is made by using fossil-free materials, minimal additions and reuse of materials in production and later on in management. The plot for the preschool is in a beautiful nature area in Backa.\r\n\r\nMARELD got the opportunity to design and produce construction drawings of the school yard. The design brings out the quality of the existing nature on the plot. There are different zones with more calm rooms for the small children as well as challenging areas for running and exploring. The design of the yard is made to the biggest part of recycled materials as well as located produced wood.",
    "Project Link*": "https://www.mareldlandskap.se/project/hoppet-preschool/",
    "Type*": "furniture",
    "Processing": "dry",
    "Function*": "structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Quercus",
    "Plant Species": "",
    "Material image": "https://www.mareldlandskap.se/wp-content/uploads/2020/09/DSCF2870-HDR-scaled.jpg",
    "Material Name*": "Recycled wood",
    "Project Title*": "Hoppet",
    "Project Author*": "Mareld Landskapsarkitekter",
    "About Project": "The preschool “Hoppet” is intended to serve as a pilot project for the City of Gothenburg with the ambitions to reach Zero CO2 emissions and as small imprint on the environment as possible. This is made by using fossil-free materials, minimal additions and reuse of materials in production and later on in management. The plot for the preschool is in a beautiful nature area in Backa.\r\n\r\nMARELD got the opportunity to design and produce construction drawings of the school yard. The design brings out the quality of the existing nature on the plot. There are different zones with more calm rooms for the small children as well as challenging areas for running and exploring. The design of the yard is made to the biggest part of recycled materials as well as located produced wood.",
    "Project Link*": "https://www.mareldlandskap.se/project/hoppet-preschool/",
    "Type*": "furniture",
    "Processing": "dry",
    "Function*": "structure",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Typha",
    "Plant Species": "",
    "Material image": "https://www.utsidan.se/cldocpart/11361.jpg",
    "Material Name*": "Bulrush Rope",
    "Project Title*": "Bulrush: marshland's supermarket",
    "Project Author*": "_norpan",
    "About Project": "Article and breakdown of the parts of a bulrush plant and potential use for its different parts.",
    "Project Link*": "https://www.utsidan.se/cldoc/kaveldun-traskmarkernas-snabbkop_7427.htm",
    "Type*": "details and paint",
    "Processing": "braid",
    "Function*": "decor",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Cannabis",
    "Plant Species": "",
    "Material image": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Kristofferuskyrkan.JPG/1600px-Kristofferuskyrkan.JPG",
    "Material Name*": "Clayplaster with hemp and straw",
    "Project Title*": "Kristofferuskyrkan",
    "Project Author*": "Johannes Riesterer",
    "About Project": "Kristofferuskyrkan in Järna, Sweden from 1999 is an example of where clay plaster has been used on exterior fasade without a large roof overhang, wich is usually stated as a \"rule\" for buildings to withstand Swedish weather. The clay craftsman Johannes Riesterer writes on his facebook page: \"This building is now celebrating 20 years. It is a church, located just south of Stockholm, Sweden. It is built i wood, at least on the inside. On the outside, it is insulated with local made lightweight straw-clay blocks more than 7000 and plastered then with a plain clayplaster.\r\nIt was considered to be impossible and still it worked beautifully. I love to use it as an example, of how we can brake rules and still succeed. The foundation is not as high as usualy recommended, the surface is way too much exposed for weather and wind not treated with any kind of paint or sealant, and the roof is just  simply criminal. The mixture of the plaster makes all the difference in the world. The amount of clay is very high in comparision to the aggregate, the sand. We have a very rich clay to start with. We have a lot of fiber, oats-straw and hemp in the mixture. The final coat has also a a very high content of cow- and horse shit. With one part clay, we have maybe 2 parts cowshit and 1 part horse. I am convinced that this makes all the difference in the world.\"",
    "Project Link*": "https://www.diva-portal.org/smash/get/diva2:1525804/FULLTEXT01.pdf",
    "Type*": "walls",
    "Processing": "mix",
    "Function*": "plaster",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  },
  {
    "Plant Genus*": "Avena",
    "Plant Species": "",
    "Material image": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Kristofferuskyrkan.JPG/1600px-Kristofferuskyrkan.JPG",
    "Material Name*": "Clayplaster with hemp and straw",
    "Project Title*": "Kristofferuskyrkan",
    "Project Author*": "Johannes Riesterer",
    "About Project": "Kristofferuskyrkan in Järna, Sweden from 1999 is an example of where clay plaster has been used on exterior facade without a large roof overhang, wich is usually stated as a \"rule\" for buildings to withstand Swedish weather. The clay craftsman Johannes Riesterer writes on his facebook page: \"This building is now celebrating 20 years. It is a church, located just south of Stockholm, Sweden. It is built i wood, at least on the inside. On the outside, it is insulated with local made lightweight straw-clay blocks more than 7000 and plastered then with a plain clayplaster.\r\nIt was considered to be impossible and still it worked beautifully. I love to use it as an example, of how we can brake rules and still succeed. The foundation is not as high as usualy recommended, the surface is way too much exposed for weather and wind not treated with any kind of paint or sealant, and the roof is just  simply criminal. The mixture of the plaster makes all the difference in the world. The amount of clay is very high in comparision to the aggregate, the sand. We have a very rich clay to start with. We have a lot of fiber, oats-straw and hemp in the mixture. The final coat has also a a very high content of cow- and horse shit. With one part clay, we have maybe 2 parts cowshit and 1 part horse. I am convinced that this makes all the difference in the world.\"",
    "Project Link*": "https://kristofferkyrkan.se/",
    "Type*": "walls",
    "Processing": "mix",
    "Function*": "plaster",
    "Comment": "",
    "source_file": "231103 Materials  - Blad1.csv"
  }
];