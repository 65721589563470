export const PHYTO_DATA: any[] = [
  {
    "Reference": "Marchiol 2004",
    "Text Title": "Phytoextraction of heavy metals by canola (Brassica napus) and radish (Raphanus sativus) grown on multicontaminated soil",
    "Article Link": "https://doi.org/10.1016/j.envpol.2004.04.001",
    "Book Link": "",
    "Plant Genus": "Brassica",
    "Plant Species": "napus",
    "Plant Cultivar": "Kabel",
    "Plant Tissue": "shoots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction and phytometabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "cadmium",
    "Contaminant Abbreviation": "Cd",
    "Removal Rate": "",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "values are from the 'treated' soils (i.e., contaminated soils)",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils and can grow in very acid and very alkaline soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "SN",
    "Height": "1.2",
    "Growth Rate": "",
    "US Hardiness Zone": "6 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "yellow flowers",
    "Seasonal Interest Summer": "yellow flowers",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Marchiol 2004",
    "Text Title": "Phytoextraction of heavy metals by canola (Brassica napus) and radish (Raphanus sativus) grown on multicontaminated soil",
    "Article Link": "https://doi.org/10.1016/j.envpol.2004.04.001",
    "Book Link": "",
    "Plant Genus": "Brassica",
    "Plant Species": "napus",
    "Plant Cultivar": "Kabel",
    "Plant Tissue": "roots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction and phytometabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "cadmium",
    "Contaminant Abbreviation": "Cd",
    "Removal Rate": "23.5",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "values are from the 'treated' soils (i.e., contaminated soils)",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils and can grow in very acid and very alkaline soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "SN",
    "Height": "1.2",
    "Growth Rate": "",
    "US Hardiness Zone": "6 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "yellow flowers",
    "Seasonal Interest Summer": "yellow flowers",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Marchiol 2004",
    "Text Title": "Phytoextraction of heavy metals by canola (Brassica napus) and radish (Raphanus sativus) grown on multicontaminated soil",
    "Article Link": "https://doi.org/10.1016/j.envpol.2004.04.001",
    "Book Link": "",
    "Plant Genus": "Brassica",
    "Plant Species": "napus",
    "Plant Cultivar": "Kabel",
    "Plant Tissue": "shoots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "chromium",
    "Contaminant Abbreviation": "Cr",
    "Removal Rate": "0.77",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "values are from the 'treated' soils (i.e., contaminated soils)",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils and can grow in very acid and very alkaline soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "SN",
    "Height": "1.2",
    "Growth Rate": "",
    "US Hardiness Zone": "6 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "yellow flowers",
    "Seasonal Interest Summer": "yellow flowers",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Marchiol 2004",
    "Text Title": "Phytoextraction of heavy metals by canola (Brassica napus) and radish (Raphanus sativus) grown on multicontaminated soil",
    "Article Link": "https://doi.org/10.1016/j.envpol.2004.04.001",
    "Book Link": "",
    "Plant Genus": "Brassica",
    "Plant Species": "napus",
    "Plant Cultivar": "Kabel",
    "Plant Tissue": "roots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "chromium",
    "Contaminant Abbreviation": "Cr",
    "Removal Rate": "82.5",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "values are from the 'treated' soils (i.e., contaminated soils)",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils and can grow in very acid and very alkaline soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "SN",
    "Height": "1.2",
    "Growth Rate": "",
    "US Hardiness Zone": "6 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "yellow flowers",
    "Seasonal Interest Summer": "yellow flowers",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Marchiol 2004",
    "Text Title": "Phytoextraction of heavy metals by canola (Brassica napus) and radish (Raphanus sativus) grown on multicontaminated soil",
    "Article Link": "https://doi.org/10.1016/j.envpol.2004.04.001",
    "Book Link": "",
    "Plant Genus": "Brassica",
    "Plant Species": "napus",
    "Plant Cultivar": "Kabel",
    "Plant Tissue": "shoots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytometabolism",
    "Contaminant Type": "heavy metal",
    "Contaminant": "copper",
    "Contaminant Abbreviation": "Cu",
    "Removal Rate": "23.6",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "values are from the 'treated' soils (i.e., contaminated soils)",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils and can grow in very acid and very alkaline soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "SN",
    "Height": "1.2",
    "Growth Rate": "",
    "US Hardiness Zone": "6 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "yellow flowers",
    "Seasonal Interest Summer": "yellow flowers",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Marchiol 2004",
    "Text Title": "Phytoextraction of heavy metals by canola (Brassica napus) and radish (Raphanus sativus) grown on multicontaminated soil",
    "Article Link": "https://doi.org/10.1016/j.envpol.2004.04.001",
    "Book Link": "",
    "Plant Genus": "Brassica",
    "Plant Species": "napus",
    "Plant Cultivar": "Kabel",
    "Plant Tissue": "roots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytostabilization and phytometabolism",
    "Contaminant Type": "heavy metal",
    "Contaminant": "copper",
    "Contaminant Abbreviation": "Cu",
    "Removal Rate": "414",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "values are from the 'treated' soils (i.e., contaminated soils)",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils and can grow in very acid and very alkaline soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "SN",
    "Height": "1.2",
    "Growth Rate": "",
    "US Hardiness Zone": "6 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "yellow flowers",
    "Seasonal Interest Summer": "yellow flowers",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Marchiol 2004",
    "Text Title": "Phytoextraction of heavy metals by canola (Brassica napus) and radish (Raphanus sativus) grown on multicontaminated soil",
    "Article Link": "https://doi.org/10.1016/j.envpol.2004.04.001",
    "Book Link": "",
    "Plant Genus": "Brassica",
    "Plant Species": "napus",
    "Plant Cultivar": "Kabel",
    "Plant Tissue": "shoots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytostabilization, phytometabolism and phytoextraction",
    "Contaminant Type": "heavy metal",
    "Contaminant": "nickel",
    "Contaminant Abbreviation": "Ni",
    "Removal Rate": "4.12",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "values are from the 'treated' soils (i.e., contaminated soils)",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils and can grow in very acid and very alkaline soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "SN",
    "Height": "1.2",
    "Growth Rate": "",
    "US Hardiness Zone": "6 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "yellow flowers",
    "Seasonal Interest Summer": "yellow flowers",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Marchiol 2004",
    "Text Title": "Phytoextraction of heavy metals by canola (Brassica napus) and radish (Raphanus sativus) grown on multicontaminated soil",
    "Article Link": "https://doi.org/10.1016/j.envpol.2004.04.001",
    "Book Link": "",
    "Plant Genus": "Brassica",
    "Plant Species": "napus",
    "Plant Cultivar": "Kabel",
    "Plant Tissue": "roots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytostabilization, phytometabolism and phytoextraction",
    "Contaminant Type": "heavy metal",
    "Contaminant": "nickel",
    "Contaminant Abbreviation": "Ni",
    "Removal Rate": "45.7",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "values are from the 'treated' soils (i.e., contaminated soils)",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils and can grow in very acid and very alkaline soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "SN",
    "Height": "1.2",
    "Growth Rate": "",
    "US Hardiness Zone": "6 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "yellow flowers",
    "Seasonal Interest Summer": "yellow flowers",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Marchiol 2004",
    "Text Title": "Phytoextraction of heavy metals by canola (Brassica napus) and radish (Raphanus sativus) grown on multicontaminated soil",
    "Article Link": "https://doi.org/10.1016/j.envpol.2004.04.001",
    "Book Link": "",
    "Plant Genus": "Brassica",
    "Plant Species": "napus",
    "Plant Cultivar": "Kabel",
    "Plant Tissue": "shoots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "lead",
    "Contaminant Abbreviation": "Pb",
    "Removal Rate": "5.48",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "values are from the 'treated' soils (i.e., contaminated soils)",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils and can grow in very acid and very alkaline soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "SN",
    "Height": "1.2",
    "Growth Rate": "",
    "US Hardiness Zone": "6 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "yellow flowers",
    "Seasonal Interest Summer": "yellow flowers",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Marchiol 2004",
    "Text Title": "Phytoextraction of heavy metals by canola (Brassica napus) and radish (Raphanus sativus) grown on multicontaminated soil",
    "Article Link": "https://doi.org/10.1016/j.envpol.2004.04.001",
    "Book Link": "",
    "Plant Genus": "Brassica",
    "Plant Species": "napus",
    "Plant Cultivar": "Kabel",
    "Plant Tissue": "roots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "lead",
    "Contaminant Abbreviation": "Pb",
    "Removal Rate": "472",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "values are from the 'treated' soils (i.e., contaminated soils)",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils and can grow in very acid and very alkaline soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "SN",
    "Height": "1.2",
    "Growth Rate": "",
    "US Hardiness Zone": "6 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "yellow flowers",
    "Seasonal Interest Summer": "yellow flowers",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Marchiol 2004",
    "Text Title": "Phytoextraction of heavy metals by canola (Brassica napus) and radish (Raphanus sativus) grown on multicontaminated soil",
    "Article Link": "https://doi.org/10.1016/j.envpol.2004.04.001",
    "Book Link": "",
    "Plant Genus": "Brassica",
    "Plant Species": "napus",
    "Plant Cultivar": "Kabel",
    "Plant Tissue": "shoots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction, phytostabilization and phytometabolizm",
    "Contaminant Type": "heavy metal",
    "Contaminant": "zinc",
    "Contaminant Abbreviation": "Zn",
    "Removal Rate": "1305",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "values are from the 'treated' soils (i.e., contaminated soils)",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils and can grow in very acid and very alkaline soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "SN",
    "Height": "1.2",
    "Growth Rate": "",
    "US Hardiness Zone": "6 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "yellow flowers",
    "Seasonal Interest Summer": "yellow flowers",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Marchiol 2004",
    "Text Title": "Phytoextraction of heavy metals by canola (Brassica napus) and radish (Raphanus sativus) grown on multicontaminated soil",
    "Article Link": "https://doi.org/10.1016/j.envpol.2004.04.001",
    "Book Link": "",
    "Plant Genus": "Brassica",
    "Plant Species": "napus",
    "Plant Cultivar": "Kabel",
    "Plant Tissue": "roots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction, phytostabilization and phytometabolizm",
    "Contaminant Type": "heavy metal",
    "Contaminant": "zinc",
    "Contaminant Abbreviation": "Zn",
    "Removal Rate": "5983",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "values are from the 'treated' soils (i.e., contaminated soils)",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils and can grow in very acid and very alkaline soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "SN",
    "Height": "1.2",
    "Growth Rate": "",
    "US Hardiness Zone": "6 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "yellow flowers",
    "Seasonal Interest Summer": "yellow flowers",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Lotfy & Mostafa 2014",
    "Text Title": "Phytoremediation of contaminated soil with cobalt and chromium",
    "Article Link": "https://www.sciencedirect.com/science/article/pii/S0375674213001362?casa_token=avMaI_GIqXQAAAAA:4qVVJ3j0toZuZDFyZpaixE2s5DPYcgeQjEoDBNq730LgGsr5Tlm0bX9UIWaqSDQX3i565M29KM4",
    "Book Link": "",
    "Plant Genus": "Helianthus",
    "Plant Species": "annuus",
    "Plant Cultivar": "",
    "Plant Tissue": "roots/shoots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "cobalt",
    "Contaminant Abbreviation": "Co",
    "Removal Rate": "57.7",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "Total uptake by whole plant, authors also provide root and shoot rates too",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "DM",
    "Shade": "SN",
    "Height": "3",
    "Growth Rate": "F",
    "US Hardiness Zone": "6 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "upright orange, red, yellow flowers",
    "Seasonal Interest Fall": "upright orange, red, yellow flowers",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Lotfy & Mostafa 2014",
    "Text Title": "Phytoremediation of contaminated soil with cobalt and chromium",
    "Article Link": "https://www.sciencedirect.com/science/article/pii/S0375674213001362?casa_token=avMaI_GIqXQAAAAA:4qVVJ3j0toZuZDFyZpaixE2s5DPYcgeQjEoDBNq730LgGsr5Tlm0bX9UIWaqSDQX3i565M29KM4",
    "Book Link": "",
    "Plant Genus": "Helianthus",
    "Plant Species": "annuus",
    "Plant Cultivar": "",
    "Plant Tissue": "roots/shoots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "cobalt",
    "Contaminant Abbreviation": "Co",
    "Removal Rate": "36.3",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "Total uptake by whole plant, authors also provide root and shoot rates too",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "DM",
    "Shade": "SN",
    "Height": "3",
    "Growth Rate": "F",
    "US Hardiness Zone": "6 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "upright orange, red, yellow flowers",
    "Seasonal Interest Fall": "upright orange, red, yellow flowers",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Lotfy & Mostafa 2014",
    "Text Title": "Phytoremediation of contaminated soil with cobalt and chromium",
    "Article Link": "https://www.sciencedirect.com/science/article/pii/S0375674213001362?casa_token=avMaI_GIqXQAAAAA:4qVVJ3j0toZuZDFyZpaixE2s5DPYcgeQjEoDBNq730LgGsr5Tlm0bX9UIWaqSDQX3i565M29KM4",
    "Book Link": "",
    "Plant Genus": "Helianthus",
    "Plant Species": "annuus",
    "Plant Cultivar": "",
    "Plant Tissue": "roots/shoots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "chromium",
    "Contaminant Abbreviation": "Cr",
    "Removal Rate": "75.8",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "Total uptake by whole plant, authors also provide root and shoot rates too",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "DM",
    "Shade": "SN",
    "Height": "3",
    "Growth Rate": "F",
    "US Hardiness Zone": "6 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "upright orange, red, yellow flowers",
    "Seasonal Interest Fall": "upright orange, red, yellow flowers",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Lotfy & Mostafa 2014",
    "Text Title": "Phytoremediation of contaminated soil with cobalt and chromium",
    "Article Link": "https://www.sciencedirect.com/science/article/pii/S0375674213001362?casa_token=avMaI_GIqXQAAAAA:4qVVJ3j0toZuZDFyZpaixE2s5DPYcgeQjEoDBNq730LgGsr5Tlm0bX9UIWaqSDQX3i565M29KM4",
    "Book Link": "",
    "Plant Genus": "Helianthus",
    "Plant Species": "annuus",
    "Plant Cultivar": "",
    "Plant Tissue": "roots/shoots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "chromium",
    "Contaminant Abbreviation": "Cr",
    "Removal Rate": "61.6",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "Total uptake by whole plant, authors also provide root and shoot rates too",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "DM",
    "Shade": "SN",
    "Height": "3",
    "Growth Rate": "F",
    "US Hardiness Zone": "6 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "upright orange, red, yellow flowers",
    "Seasonal Interest Fall": "upright orange, red, yellow flowers",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Hutchinson 2000",
    "Text Title": "Determining uptake of ‘non-labile’ soil cadmium by Thlaspi caerulescens using isotopic dilution techniques",
    "Article Link": "https://nph.onlinelibrary.wiley.com/doi/pdf/10.1046/j.1469-8137.2000.00657.x",
    "Book Link": "",
    "Plant Genus": "Lepidium",
    "Plant Species": "heterophyllum",
    "Plant Cultivar": "",
    "Plant Tissue": "shoots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction and phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "cadmium",
    "Contaminant Abbreviation": "Cd",
    "Removal Rate": "17.4",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "study reports concentration values for plants from different populations grown in pots with soils in three sites within the sewage and mine contaminated regions. I take the average of these (see Table 2)",
    "Plant Category": "herbaceous",
    "Soil Ph": "pH: mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "SN",
    "Height": ".3 m",
    "Growth Rate": "M",
    "US Hardiness Zone": "",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "white flower",
    "Seasonal Interest Summer": "white flower",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Hammer 2002",
    "Text Title": "Changes in the Rhizosphere of Metal-Accumulating Plants Evidenced by Chemical Extractants",
    "Article Link": "https://acsess.onlinelibrary.wiley.com/doi/pdfdirect/10.2134/jeq2002.1561?casa_token=TYQqZ4bAMZQAAAAA:mZJGZLuPBBiHfLZ6laM6OCHqBH-OJDrsxcU9M1Bl6ILyYwKAqwFtgAMaYrEKjPNil2EdYTKOYLgAC9o",
    "Book Link": "",
    "Plant Genus": "Salix",
    "Plant Species": "viminalis",
    "Plant Cultivar": "",
    "Plant Tissue": "leaves",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction, phytostabilization and phytometabolism",
    "Contaminant Type": "heavy metal",
    "Contaminant": "zinc",
    "Contaminant Abbreviation": "Zn",
    "Removal Rate": "353",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "Study reports other minerals (e.g., Mg, P, Fe, CA). grew plants on two soils types. Also includes T. arvense as a non-accumulator 'control' plant, but excluded here bc non-acc plant. report means from 4 'reps' (plants?)",
    "Plant Category": "shrub",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "MWe",
    "Shade": "N",
    "Height": "6",
    "Growth Rate": "F",
    "US Hardiness Zone": "4  - 8",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "flower",
    "Seasonal Interest Summer": "color ranges from yellowish to bluish color.",
    "Seasonal Interest Fall": "foliage is yellowis",
    "Seasonal Interest Winter": "multi-stemmed shrub. foliage can persist in winter.",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Hammer 2002",
    "Text Title": "Changes in the Rhizosphere of Metal-Accumulating Plants Evidenced by Chemical Extractants",
    "Article Link": "https://acsess.onlinelibrary.wiley.com/doi/pdfdirect/10.2134/jeq2002.1561?casa_token=TYQqZ4bAMZQAAAAA:mZJGZLuPBBiHfLZ6laM6OCHqBH-OJDrsxcU9M1Bl6ILyYwKAqwFtgAMaYrEKjPNil2EdYTKOYLgAC9o",
    "Book Link": "",
    "Plant Genus": "Salix",
    "Plant Species": "viminalis",
    "Plant Cultivar": "",
    "Plant Tissue": "stems",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction, phytostabilization and phytometabolism",
    "Contaminant Type": "heavy metal",
    "Contaminant": "zinc",
    "Contaminant Abbreviation": "Zn",
    "Removal Rate": "96",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "Study reports other minerals (e.g., Mg, P, Fe, CA). grew plants on two soils types. Also includes T. arvense as a non-accumulator 'control' plant, but excluded here bc non-acc plant. report means from 4 'reps' (plants?)",
    "Plant Category": "shrub",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "MWe",
    "Shade": "N",
    "Height": "6",
    "Growth Rate": "F",
    "US Hardiness Zone": "4  - 8",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "flower",
    "Seasonal Interest Summer": "color ranges from yellowish to bluish color.",
    "Seasonal Interest Fall": "foliage is yellowis",
    "Seasonal Interest Winter": "multi-stemmed shrub. foliage can persist in winter.",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Hammer 2002",
    "Text Title": "Changes in the Rhizosphere of Metal-Accumulating Plants Evidenced by Chemical Extractants",
    "Article Link": "https://acsess.onlinelibrary.wiley.com/doi/pdfdirect/10.2134/jeq2002.1561?casa_token=TYQqZ4bAMZQAAAAA:mZJGZLuPBBiHfLZ6laM6OCHqBH-OJDrsxcU9M1Bl6ILyYwKAqwFtgAMaYrEKjPNil2EdYTKOYLgAC9o",
    "Book Link": "",
    "Plant Genus": "Salix",
    "Plant Species": "viminalis",
    "Plant Cultivar": "",
    "Plant Tissue": "roots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction, phytostabilization and phytometabolism",
    "Contaminant Type": "heavy metal",
    "Contaminant": "zinc",
    "Contaminant Abbreviation": "Zn",
    "Removal Rate": "181",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "Study reports other minerals (e.g., Mg, P, Fe, CA). grew plants on two soils types. Also includes T. arvense as a non-accumulator 'control' plant, but excluded here bc non-acc plant. report means from 4 'reps' (plants?)",
    "Plant Category": "shrub",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "MWe",
    "Shade": "N",
    "Height": "6",
    "Growth Rate": "F",
    "US Hardiness Zone": "4  - 8",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "flower",
    "Seasonal Interest Summer": "color ranges from yellowish to bluish color.",
    "Seasonal Interest Fall": "foliage is yellowis",
    "Seasonal Interest Winter": "multi-stemmed shrub. foliage can persist in winter.",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Hammer 2002",
    "Text Title": "Changes in the Rhizosphere of Metal-Accumulating Plants Evidenced by Chemical Extractants",
    "Article Link": "https://acsess.onlinelibrary.wiley.com/doi/pdfdirect/10.2134/jeq2002.1561?casa_token=TYQqZ4bAMZQAAAAA:mZJGZLuPBBiHfLZ6laM6OCHqBH-OJDrsxcU9M1Bl6ILyYwKAqwFtgAMaYrEKjPNil2EdYTKOYLgAC9o",
    "Book Link": "",
    "Plant Genus": "Salix",
    "Plant Species": "viminalis",
    "Plant Cultivar": "",
    "Plant Tissue": "leaves",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction and phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "cadmium",
    "Contaminant Abbreviation": "Cd",
    "Removal Rate": "3.56",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "Study reports other minerals (e.g., Mg, P, Fe, CA). grew plants on two soils types. Also includes T. arvense as a non-accumulator 'control' plant, but excluded here bc non-acc plant. report means from 4 'reps' (plants?)",
    "Plant Category": "shrub",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "MWe",
    "Shade": "N",
    "Height": "6",
    "Growth Rate": "F",
    "US Hardiness Zone": "4  - 8",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "flower",
    "Seasonal Interest Summer": "color ranges from yellowish to bluish color.",
    "Seasonal Interest Fall": "foliage is yellowis",
    "Seasonal Interest Winter": "multi-stemmed shrub. foliage can persist in winter.",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Hammer 2002",
    "Text Title": "Changes in the Rhizosphere of Metal-Accumulating Plants Evidenced by Chemical Extractants",
    "Article Link": "https://acsess.onlinelibrary.wiley.com/doi/pdfdirect/10.2134/jeq2002.1561?casa_token=TYQqZ4bAMZQAAAAA:mZJGZLuPBBiHfLZ6laM6OCHqBH-OJDrsxcU9M1Bl6ILyYwKAqwFtgAMaYrEKjPNil2EdYTKOYLgAC9o",
    "Book Link": "",
    "Plant Genus": "Salix",
    "Plant Species": "viminalis",
    "Plant Cultivar": "",
    "Plant Tissue": "stems",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction and phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "cadmium",
    "Contaminant Abbreviation": "Cd",
    "Removal Rate": "1.77",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "Study reports other minerals (e.g., Mg, P, Fe, CA). grew plants on two soils types. Also includes T. arvense as a non-accumulator 'control' plant, but excluded here bc non-acc plant. report means from 4 'reps' (plants?)",
    "Plant Category": "shrub",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "MWe",
    "Shade": "N",
    "Height": "6",
    "Growth Rate": "F",
    "US Hardiness Zone": "4  - 8",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "flower",
    "Seasonal Interest Summer": "color ranges from yellowish to bluish color.",
    "Seasonal Interest Fall": "foliage is yellowis",
    "Seasonal Interest Winter": "multi-stemmed shrub. foliage can persist in winter.",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Hammer 2002",
    "Text Title": "Changes in the Rhizosphere of Metal-Accumulating Plants Evidenced by Chemical Extractants",
    "Article Link": "https://acsess.onlinelibrary.wiley.com/doi/pdfdirect/10.2134/jeq2002.1561?casa_token=TYQqZ4bAMZQAAAAA:mZJGZLuPBBiHfLZ6laM6OCHqBH-OJDrsxcU9M1Bl6ILyYwKAqwFtgAMaYrEKjPNil2EdYTKOYLgAC9o",
    "Book Link": "",
    "Plant Genus": "Salix",
    "Plant Species": "viminalis",
    "Plant Cultivar": "",
    "Plant Tissue": "roots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction and phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "cadmium",
    "Contaminant Abbreviation": "Cd",
    "Removal Rate": "1.49",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "Study reports other minerals (e.g., Mg, P, Fe, CA). grew plants on two soils types. Also includes T. arvense as a non-accumulator 'control' plant, but excluded here bc non-acc plant. report means from 4 'reps' (plants?)",
    "Plant Category": "shrub",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "MWe",
    "Shade": "N",
    "Height": "6",
    "Growth Rate": "F",
    "US Hardiness Zone": "4  - 8",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "flower",
    "Seasonal Interest Summer": "color ranges from yellowish to bluish color.",
    "Seasonal Interest Fall": "foliage is yellowis",
    "Seasonal Interest Winter": "multi-stemmed shrub. foliage can persist in winter.",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Hammer 2002",
    "Text Title": "Changes in the Rhizosphere of Metal-Accumulating Plants Evidenced by Chemical Extractants",
    "Article Link": "https://acsess.onlinelibrary.wiley.com/doi/pdfdirect/10.2134/jeq2002.1561?casa_token=TYQqZ4bAMZQAAAAA:mZJGZLuPBBiHfLZ6laM6OCHqBH-OJDrsxcU9M1Bl6ILyYwKAqwFtgAMaYrEKjPNil2EdYTKOYLgAC9o",
    "Book Link": "",
    "Plant Genus": "Salix",
    "Plant Species": "viminalis",
    "Plant Cultivar": "",
    "Plant Tissue": "leaves",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction, phytostabilization and phytometabolism",
    "Contaminant Type": "heavy metal",
    "Contaminant": "zinc",
    "Contaminant Abbreviation": "Zn",
    "Removal Rate": "2326",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "Study reports other minerals (e.g., Mg, P, Fe, CA). grew plants on two soils types. Also includes T. arvense as a non-accumulator 'control' plant, but excluded here bc non-acc plant. report means from 4 'reps' (plants?)",
    "Plant Category": "shrub",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "MWe",
    "Shade": "N",
    "Height": "6",
    "Growth Rate": "F",
    "US Hardiness Zone": "4  - 8",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "flower",
    "Seasonal Interest Summer": "color ranges from yellowish to bluish color.",
    "Seasonal Interest Fall": "foliage is yellowis",
    "Seasonal Interest Winter": "multi-stemmed shrub. foliage can persist in winter.",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Hammer 2002",
    "Text Title": "Changes in the Rhizosphere of Metal-Accumulating Plants Evidenced by Chemical Extractants",
    "Article Link": "https://acsess.onlinelibrary.wiley.com/doi/pdfdirect/10.2134/jeq2002.1561?casa_token=TYQqZ4bAMZQAAAAA:mZJGZLuPBBiHfLZ6laM6OCHqBH-OJDrsxcU9M1Bl6ILyYwKAqwFtgAMaYrEKjPNil2EdYTKOYLgAC9o",
    "Book Link": "",
    "Plant Genus": "Salix",
    "Plant Species": "viminalis",
    "Plant Cultivar": "",
    "Plant Tissue": "stems",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction, phytostabilization and phytometabolism",
    "Contaminant Type": "heavy metal",
    "Contaminant": "zinc",
    "Contaminant Abbreviation": "Zn",
    "Removal Rate": "371",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "Study reports other minerals (e.g., Mg, P, Fe, CA). grew plants on two soils types. Also includes T. arvense as a non-accumulator 'control' plant, but excluded here bc non-acc plant. report means from 4 'reps' (plants?)",
    "Plant Category": "shrub",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "MWe",
    "Shade": "N",
    "Height": "6",
    "Growth Rate": "F",
    "US Hardiness Zone": "4  - 8",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "flower",
    "Seasonal Interest Summer": "color ranges from yellowish to bluish color.",
    "Seasonal Interest Fall": "foliage is yellowis",
    "Seasonal Interest Winter": "multi-stemmed shrub. foliage can persist in winter.",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Hammer 2002",
    "Text Title": "Changes in the Rhizosphere of Metal-Accumulating Plants Evidenced by Chemical Extractants",
    "Article Link": "https://acsess.onlinelibrary.wiley.com/doi/pdfdirect/10.2134/jeq2002.1561?casa_token=TYQqZ4bAMZQAAAAA:mZJGZLuPBBiHfLZ6laM6OCHqBH-OJDrsxcU9M1Bl6ILyYwKAqwFtgAMaYrEKjPNil2EdYTKOYLgAC9o",
    "Book Link": "",
    "Plant Genus": "Salix",
    "Plant Species": "viminalis",
    "Plant Cultivar": "",
    "Plant Tissue": "roots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction, phytostabilization and phytometabolism",
    "Contaminant Type": "heavy metal",
    "Contaminant": "zinc",
    "Contaminant Abbreviation": "Zn",
    "Removal Rate": "1374",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "Study reports other minerals (e.g., Mg, P, Fe, CA). grew plants on two soils types. Also includes T. arvense as a non-accumulator 'control' plant, but excluded here bc non-acc plant. report means from 4 'reps' (plants?)",
    "Plant Category": "shrub",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "MWe",
    "Shade": "N",
    "Height": "6",
    "Growth Rate": "F",
    "US Hardiness Zone": "4  - 8",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "flower",
    "Seasonal Interest Summer": "color ranges from yellowish to bluish color.",
    "Seasonal Interest Fall": "foliage is yellowis",
    "Seasonal Interest Winter": "multi-stemmed shrub. foliage can persist in winter.",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Hammer 2002",
    "Text Title": "Changes in the Rhizosphere of Metal-Accumulating Plants Evidenced by Chemical Extractants",
    "Article Link": "https://acsess.onlinelibrary.wiley.com/doi/pdfdirect/10.2134/jeq2002.1561?casa_token=TYQqZ4bAMZQAAAAA:mZJGZLuPBBiHfLZ6laM6OCHqBH-OJDrsxcU9M1Bl6ILyYwKAqwFtgAMaYrEKjPNil2EdYTKOYLgAC9o",
    "Book Link": "",
    "Plant Genus": "Salix",
    "Plant Species": "viminalis",
    "Plant Cultivar": "",
    "Plant Tissue": "leaves",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction and phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "cadmium",
    "Contaminant Abbreviation": "Cd",
    "Removal Rate": "4.87",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "Study reports other minerals (e.g., Mg, P, Fe, CA). grew plants on two soils types. Also includes T. arvense as a non-accumulator 'control' plant, but excluded here bc non-acc plant. report means from 4 'reps' (plants?)",
    "Plant Category": "shrub",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "MWe",
    "Shade": "N",
    "Height": "6",
    "Growth Rate": "F",
    "US Hardiness Zone": "4  - 8",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "flower",
    "Seasonal Interest Summer": "color ranges from yellowish to bluish color.",
    "Seasonal Interest Fall": "foliage is yellowis",
    "Seasonal Interest Winter": "multi-stemmed shrub. foliage can persist in winter.",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Hammer 2002",
    "Text Title": "Changes in the Rhizosphere of Metal-Accumulating Plants Evidenced by Chemical Extractants",
    "Article Link": "https://acsess.onlinelibrary.wiley.com/doi/pdfdirect/10.2134/jeq2002.1561?casa_token=TYQqZ4bAMZQAAAAA:mZJGZLuPBBiHfLZ6laM6OCHqBH-OJDrsxcU9M1Bl6ILyYwKAqwFtgAMaYrEKjPNil2EdYTKOYLgAC9o",
    "Book Link": "",
    "Plant Genus": "Salix",
    "Plant Species": "viminalis",
    "Plant Cultivar": "",
    "Plant Tissue": "stems",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction and phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "cadmium",
    "Contaminant Abbreviation": "Cd",
    "Removal Rate": "1.75",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "Study reports other minerals (e.g., Mg, P, Fe, CA). grew plants on two soils types. Also includes T. arvense as a non-accumulator 'control' plant, but excluded here bc non-acc plant. report means from 4 'reps' (plants?)",
    "Plant Category": "shrub",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "MWe",
    "Shade": "N",
    "Height": "6",
    "Growth Rate": "F",
    "US Hardiness Zone": "4  - 8",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "flower",
    "Seasonal Interest Summer": "color ranges from yellowish to bluish color.",
    "Seasonal Interest Fall": "foliage is yellowis",
    "Seasonal Interest Winter": "multi-stemmed shrub. foliage can persist in winter.",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Hammer 2002",
    "Text Title": "Changes in the Rhizosphere of Metal-Accumulating Plants Evidenced by Chemical Extractants",
    "Article Link": "https://acsess.onlinelibrary.wiley.com/doi/pdfdirect/10.2134/jeq2002.1561?casa_token=TYQqZ4bAMZQAAAAA:mZJGZLuPBBiHfLZ6laM6OCHqBH-OJDrsxcU9M1Bl6ILyYwKAqwFtgAMaYrEKjPNil2EdYTKOYLgAC9o",
    "Book Link": "",
    "Plant Genus": "Salix",
    "Plant Species": "viminalis",
    "Plant Cultivar": "",
    "Plant Tissue": "roots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction and phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "cadmium",
    "Contaminant Abbreviation": "Cd",
    "Removal Rate": "1.04",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "Study reports other minerals (e.g., Mg, P, Fe, CA). grew plants on two soils types. Also includes T. arvense as a non-accumulator 'control' plant, but excluded here bc non-acc plant. report means from 4 'reps' (plants?)",
    "Plant Category": "shrub",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "MWe",
    "Shade": "N",
    "Height": "6",
    "Growth Rate": "F",
    "US Hardiness Zone": "4  - 8",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "flower",
    "Seasonal Interest Summer": "color ranges from yellowish to bluish color.",
    "Seasonal Interest Fall": "foliage is yellowis",
    "Seasonal Interest Winter": "multi-stemmed shrub. foliage can persist in winter.",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Bi 2003",
    "Text Title": "Influence of early stages of arbuscular mycorrhiza on uptake of zinc and phosphorus by red clover from a low-phosphorus soil amended with zinc and phosphorus",
    "Article Link": "https://www.sciencedirect.com/science/article/pii/S0045653502002278?casa_token=Lwto6m48KwUAAAAA:ajlKd9HDoTq600k79a7Q2q63l9r-9AjIIx7uCwG9Kr6PVXA4ELHVf8qkuIzfn4sCOQpd26zho44",
    "Book Link": "",
    "Plant Genus": "Trifolium",
    "Plant Species": "pratense",
    "Plant Cultivar": "Merwe",
    "Plant Tissue": "shoots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction, phytostabilization and phytometabolism",
    "Contaminant Type": "heavy metal",
    "Contaminant": "zinc",
    "Contaminant Abbreviation": "Zn",
    "Removal Rate": "1430",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "values are from non-fert, non-AM plants with the highest Zn concentration (400 mg/kg)",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": ".6 m",
    "Growth Rate": "M",
    "US Hardiness Zone": "5 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "green ground cover",
    "Seasonal Interest Summer": "green ground cover",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Bi 2003",
    "Text Title": "Influence of early stages of arbuscular mycorrhiza on uptake of zinc and phosphorus by red clover from a low-phosphorus soil amended with zinc and phosphorus",
    "Article Link": "https://www.sciencedirect.com/science/article/pii/S0045653502002278?casa_token=Lwto6m48KwUAAAAA:ajlKd9HDoTq600k79a7Q2q63l9r-9AjIIx7uCwG9Kr6PVXA4ELHVf8qkuIzfn4sCOQpd26zho44",
    "Book Link": "",
    "Plant Genus": "Trifolium",
    "Plant Species": "pratense",
    "Plant Cultivar": "Merwe",
    "Plant Tissue": "shoots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytometabolism and phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "copper",
    "Contaminant Abbreviation": "Cu",
    "Removal Rate": "26.1",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "values are from non-fert, non-AM plants with the highest Zn concentration (400 mg/kg)",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": ".6 m",
    "Growth Rate": "M",
    "US Hardiness Zone": "5 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "green ground cover",
    "Seasonal Interest Summer": "green ground cover",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Bi 2003",
    "Text Title": "Influence of early stages of arbuscular mycorrhiza on uptake of zinc and phosphorus by red clover from a low-phosphorus soil amended with zinc and phosphorus",
    "Article Link": "https://www.sciencedirect.com/science/article/pii/S0045653502002278?casa_token=Lwto6m48KwUAAAAA:ajlKd9HDoTq600k79a7Q2q63l9r-9AjIIx7uCwG9Kr6PVXA4ELHVf8qkuIzfn4sCOQpd26zho44",
    "Book Link": "",
    "Plant Genus": "Trifolium",
    "Plant Species": "pratense",
    "Plant Cultivar": "Merwe",
    "Plant Tissue": "roots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction, phytostabilization and phytometabolism",
    "Contaminant Type": "heavy metal",
    "Contaminant": "zinc",
    "Contaminant Abbreviation": "Zn",
    "Removal Rate": "9791",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "values are from non-fert, non-AM plants with the highest Zn concentration (400 mg/kg)",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": ".6 m",
    "Growth Rate": "M",
    "US Hardiness Zone": "5 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "green ground cover",
    "Seasonal Interest Summer": "green ground cover",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Bi 2003",
    "Text Title": "Influence of early stages of arbuscular mycorrhiza on uptake of zinc and phosphorus by red clover from a low-phosphorus soil amended with zinc and phosphorus",
    "Article Link": "https://www.sciencedirect.com/science/article/pii/S0045653502002278?casa_token=Lwto6m48KwUAAAAA:ajlKd9HDoTq600k79a7Q2q63l9r-9AjIIx7uCwG9Kr6PVXA4ELHVf8qkuIzfn4sCOQpd26zho44",
    "Book Link": "",
    "Plant Genus": "Trifolium",
    "Plant Species": "pratense",
    "Plant Cultivar": "Merwe",
    "Plant Tissue": "roots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytometabolism and phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "copper",
    "Contaminant Abbreviation": "Cu",
    "Removal Rate": "51",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "values are from non-fert, non-AM plants with the highest Zn concentration (400 mg/kg)",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": ".6 m",
    "Growth Rate": "M",
    "US Hardiness Zone": "5 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "green ground cover",
    "Seasonal Interest Summer": "green ground cover",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Chen 2003",
    "Text Title": "The role of arbuscular mycorrhiza in zinc uptake by red clover growing in a calcareous soil spiked with various quantities of zinc",
    "Article Link": "https://www.sciencedirect.com/science/article/pii/S004565350200228X?casa_token=iX2CKSljzMsAAAAA:4YeGpqHEPKNzAoJ3jN3Au3daFX0iN4p-WOU50FGb2g-VMzD_YWY5gXnXS-fZ_vSShX6VmlOMprg",
    "Book Link": "",
    "Plant Genus": "Trifolium",
    "Plant Species": "pratense",
    "Plant Cultivar": "",
    "Plant Tissue": "shoots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction, phytostabilization and phytometabolism",
    "Contaminant Type": "heavy metal",
    "Contaminant": "zinc",
    "Contaminant Abbreviation": "Zn",
    "Removal Rate": "153.1",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "study has three levels of Zn addition crossed with AM-fungi innoculation. Report values from max Zn conc (400 mg/kg) and no AM-fungi. NB AM-Fungi did increase concentrations",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": ".6 m",
    "Growth Rate": "M",
    "US Hardiness Zone": "5 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "green ground cover",
    "Seasonal Interest Summer": "green ground cover",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Chen 2003",
    "Text Title": "The role of arbuscular mycorrhiza in zinc uptake by red clover growing in a calcareous soil spiked with various quantities of zinc",
    "Article Link": "https://www.sciencedirect.com/science/article/pii/S004565350200228X?casa_token=iX2CKSljzMsAAAAA:4YeGpqHEPKNzAoJ3jN3Au3daFX0iN4p-WOU50FGb2g-VMzD_YWY5gXnXS-fZ_vSShX6VmlOMprg",
    "Book Link": "",
    "Plant Genus": "Trifolium",
    "Plant Species": "pratense",
    "Plant Cultivar": "",
    "Plant Tissue": "roots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction, phytostabilization and phytometabolism",
    "Contaminant Type": "heavy metal",
    "Contaminant": "zinc",
    "Contaminant Abbreviation": "Zn",
    "Removal Rate": "298.6",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "study has three levels of Zn addition crossed with AM-fungi innoculation. Report values from max Zn conc (400 mg/kg) and no AM-fungi. NB AM-Fungi did increase concentrations",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": ".6 m",
    "Growth Rate": "M",
    "US Hardiness Zone": "5 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "green ground cover",
    "Seasonal Interest Summer": "green ground cover",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Delorme 2001",
    "Text Title": "Influence of the zinc hyperaccumulator Thlaspi caerulescens J. & C. Presl. and the nonmetal accumulator Trifolium pratense L. on soil microbial populations",
    "Article Link": "https://cdnsciencepub.com/doi/abs/10.1139/w01-067?casa_token=0QDiSQhG2F4AAAAA:SIAtDkvt3GSPcfIQFL73FuWreda8HeL617uutkof-8-t0GtynQrI6azWjQSI0ypOUKseVb-5WlthSQ",
    "Book Link": "",
    "Plant Genus": "Trifolium",
    "Plant Species": "pratense",
    "Plant Cultivar": "",
    "Plant Tissue": "shoots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction and phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "cadmium",
    "Contaminant Abbreviation": "Cd",
    "Removal Rate": "15.3",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "report values from the heavy metal soils and pH 5.8.",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": ".6 m",
    "Growth Rate": "M",
    "US Hardiness Zone": "5 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "green ground cover",
    "Seasonal Interest Summer": "green ground cover",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Delorme 2001",
    "Text Title": "Influence of the zinc hyperaccumulator Thlaspi caerulescens J. & C. Presl. and the nonmetal accumulator Trifolium pratense L. on soil microbial populations",
    "Article Link": "https://cdnsciencepub.com/doi/abs/10.1139/w01-067?casa_token=0QDiSQhG2F4AAAAA:SIAtDkvt3GSPcfIQFL73FuWreda8HeL617uutkof-8-t0GtynQrI6azWjQSI0ypOUKseVb-5WlthSQ",
    "Book Link": "",
    "Plant Genus": "Trifolium",
    "Plant Species": "pratense",
    "Plant Cultivar": "",
    "Plant Tissue": "shoots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction, phytostabilization and phytometabolism",
    "Contaminant Type": "heavy metal",
    "Contaminant": "zinc",
    "Contaminant Abbreviation": "Zn",
    "Removal Rate": "281.9",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "report values from the heavy metal soils and pH 5.8.",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": ".6 m",
    "Growth Rate": "M",
    "US Hardiness Zone": "5 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "green ground cover",
    "Seasonal Interest Summer": "green ground cover",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Li 2001",
    "Text Title": "Changes in soil solution Zn and pH and uptake of Zn by arbuscular mycorrhizal red clover in Zn-contaminated soil",
    "Article Link": "10.1016/s0045-6535(00)00126-0",
    "Book Link": "",
    "Plant Genus": "Trifolium",
    "Plant Species": "pratense",
    "Plant Cultivar": "Merwe",
    "Plant Tissue": "shoots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction, phytostabilization and phytometabolism",
    "Contaminant Type": "heavy metal",
    "Contaminant": "zinc",
    "Contaminant Abbreviation": "Zn",
    "Removal Rate": "218.2",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "values are from  non-AM plants with the highest Zn concentration (1000 mg/kg)",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": ".6 m",
    "Growth Rate": "M",
    "US Hardiness Zone": "5 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "green ground cover",
    "Seasonal Interest Summer": "green ground cover",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Li 2001",
    "Text Title": "Changes in soil solution Zn and pH and uptake of Zn by arbuscular mycorrhizal red clover in Zn-contaminated soil",
    "Article Link": "10.1016/s0045-6535(00)00126-0",
    "Book Link": "",
    "Plant Genus": "Trifolium",
    "Plant Species": "pratense",
    "Plant Cultivar": "Merwe",
    "Plant Tissue": "roots",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction, phytostabilization and phytometabolism",
    "Contaminant Type": "heavy metal",
    "Contaminant": "zinc",
    "Contaminant Abbreviation": "Zn",
    "Removal Rate": "721.8",
    "Removal Rate Units": "mg/kg",
    "Timeline": "",
    "Notes": "values are from  non-AM plants with the highest Zn concentration (1000 mg/kg)",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": ".6 m",
    "Growth Rate": "M",
    "US Hardiness Zone": "5 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "green ground cover",
    "Seasonal Interest Summer": "green ground cover",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Cook and Hesterberg, 2012 Fagiolo and Ferro, 2004",
    "Text Title": "Phyto, 2015 Kennen and Kirkwood",
    "Article Link": "",
    "Book Link": "https://www.taylorfrancis.com/books/mono/10.4324/9781315746661/phyto-niall-kirkwood-kate-kennen",
    "Plant Genus": "Acer",
    "Plant Species": "platanoides",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "phytovolatilzation, phytodegradation and Rhizodegradation",
    "Contaminant Type": "petroleum",
    "Contaminant": "benzene, toluene, ethyl benzene, and xylene",
    "Contaminant Abbreviation": "BTEX",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "tree",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils and can grow in very alkaline soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "SN",
    "Height": "21",
    "Growth Rate": "F",
    "US Hardiness Zone": "3 - 7",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "green bloom",
    "Seasonal Interest Summer": "green foliage",
    "Seasonal Interest Fall": "foliage of yellows, oranges, and browns.",
    "Seasonal Interest Winter": "form is rounded, spreading or horizontal, upright or erect.",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Tischer and Hubner, 2002",
    "Text Title": "Phyto, 2015 Kennen and Kirkwood",
    "Article Link": "",
    "Book Link": "https://www.taylorfrancis.com/books/mono/10.4324/9781315746661/phyto-niall-kirkwood-kate-kennen",
    "Plant Genus": "Alnus",
    "Plant Species": "glutinosa",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "phytovolatilzation, phytodegradation and Rhizodegradation",
    "Contaminant Type": "petroleum",
    "Contaminant": "motor oil hydrocarbon",
    "Contaminant Abbreviation": "MOH",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "tree",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "MH",
    "Moisture": "MWe",
    "Shade": "SN",
    "Height": "25 m",
    "Growth Rate": "F",
    "US Hardiness Zone": "3 - 7",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "bloom",
    "Seasonal Interest Summer": "green foliage",
    "Seasonal Interest Fall": "catkins",
    "Seasonal Interest Winter": "tall form",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Cook and Hesterberg, 2012 Rezek et al., 2009",
    "Text Title": "Phyto, 2015 Kennen and Kirkwood",
    "Article Link": "",
    "Book Link": "https://www.taylorfrancis.com/books/mono/10.4324/9781315746661/phyto-niall-kirkwood-kate-kennen",
    "Plant Genus": "Betula",
    "Plant Species": "pendula",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "rhizodegradation and phytodegradation",
    "Contaminant Type": "petroleum",
    "Contaminant": "polycyclic aromatic hydrocarbons",
    "Contaminant Abbreviation": "PAH",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "tree",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils and can grow in very acid soils",
    "Soil type": "LMH",
    "Moisture": "DM",
    "Shade": "N",
    "Height": "20",
    "Growth Rate": "F",
    "US Hardiness Zone": "2 - 6",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "brown bloom",
    "Seasonal Interest Summer": "green foliage. seeds ripen.",
    "Seasonal Interest Fall": "foligae of gold and yellow",
    "Seasonal Interest Winter": "form is oval, pyramidal, weeping",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Kilakow, 2006c, Palmrith et al., 2006",
    "Text Title": "Phyto, 2015 Kennen and Kirkwood",
    "Article Link": "",
    "Book Link": "https://www.taylorfrancis.com/books/mono/10.4324/9781315746661/phyto-niall-kirkwood-kate-kennen",
    "Plant Genus": "Poa",
    "Plant Species": "pratensis",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "",
    "Contaminant Type": "petroleum",
    "Contaminant": "total petroleum hydrocarbon",
    "Contaminant Abbreviation": "TPH",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "grasses and sedges",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": "15-100 cm",
    "Growth Rate": "M",
    "US Hardiness Zone": "3 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "bloom",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Kilakow, 2006c, Palmrith et al., 2006",
    "Text Title": "Phyto, 2015 Kennen and Kirkwood",
    "Article Link": "",
    "Book Link": "https://www.taylorfrancis.com/books/mono/10.4324/9781315746661/phyto-niall-kirkwood-kate-kennen",
    "Plant Genus": "Poa",
    "Plant Species": "pratensis",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "rhizodegradation and phytodegradation",
    "Contaminant Type": "petroleum",
    "Contaminant": "polycyclic aromatic hydrocarbons",
    "Contaminant Abbreviation": "PAH",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "grasses and sedges",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": "15-100 cm",
    "Growth Rate": "M",
    "US Hardiness Zone": "3 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "bloom",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Liužinas et al., 2003",
    "Text Title": "Fitoremediācijas izmantošanas iespējas Latvijā, 2011, Valujeva, Grīnfelde and Straupe",
    "Article Link": "",
    "Book Link": "http://www.murzl.llu.lv/?p=528",
    "Plant Genus": "Tanacetum",
    "Plant Species": "vulgare",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "phytovolatilzation, phytodegradation and Rhizodegradation",
    "Contaminant Type": "petroleum",
    "Contaminant": "mazuts",
    "Contaminant Abbreviation": "Aliphatic petrochemicals",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "DM",
    "Shade": "N",
    "Height": "40-160 cm",
    "Growth Rate": "F",
    "US Hardiness Zone": "3 - 9",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "yellow bloom",
    "Seasonal Interest Fall": "yellow bloom",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Palmroth et al., 2002",
    "Text Title": "Fitoremediācijas izmantošanas iespējas Latvijā, 2011, Valujeva, Grīnfelde and Straupe",
    "Article Link": "",
    "Book Link": "http://www.murzl.llu.lv/?p=528",
    "Plant Genus": "Pinus",
    "Plant Species": "sylvestris",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "phytovolatilzation, phytodegradation and Rhizodegradation",
    "Contaminant Type": "petroleum",
    "Contaminant": "diesel",
    "Contaminant Abbreviation": "Aliphatic petrochemicals",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "tree",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline)",
    "Soil type": "LM",
    "Moisture": "DMWe",
    "Shade": "SN",
    "Height": "20-30 m",
    "Growth Rate": "F",
    "US Hardiness Zone": "3 - 7",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "yellow bloom",
    "Seasonal Interest Summer": "form is oval, pyramidal",
    "Seasonal Interest Fall": "form is oval, pyramidal",
    "Seasonal Interest Winter": "form is oval, pyramidal",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Liužinas et al., 2003",
    "Text Title": "Fitoremediācijas izmantošanas iespējas Latvijā, 2011, Valujeva, Grīnfelde and Straupe",
    "Article Link": "",
    "Book Link": "http://www.murzl.llu.lv/?p=528",
    "Plant Genus": "Tussilago",
    "Plant Species": "farfara",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "phytovolatilzation, phytodegradation and Rhizodegradation",
    "Contaminant Type": "petroleum",
    "Contaminant": "mazuts",
    "Contaminant Abbreviation": "Aliphatic petrochemicals",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils and can grow in very alkaline soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "SN",
    "Height": "5-15 cm",
    "Growth Rate": "F",
    "US Hardiness Zone": "4 - 8",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "yellow flowers",
    "Seasonal Interest Summer": "",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "yellow flowers",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Liužinas et al., 2003",
    "Text Title": "Fitoremediācijas izmantošanas iespējas Latvijā, 2011, Valujeva, Grīnfelde and Straupe",
    "Article Link": "",
    "Book Link": "http://www.murzl.llu.lv/?p=528",
    "Plant Genus": "Artemisia",
    "Plant Species": "campestris",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "phytovolatilzation, phytodegradation and Rhizodegradation",
    "Contaminant Type": "petroleum",
    "Contaminant": "mazuts",
    "Contaminant Abbreviation": "Aliphatic petrochemicals",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "herbaceous",
    "Soil Ph": "neutral and basic (mildly alkaline) soils",
    "Soil type": "LM",
    "Moisture": "DM",
    "Shade": "SN",
    "Height": "30-150 cm",
    "Growth Rate": "",
    "US Hardiness Zone": "4 - 8",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "yellow flowers",
    "Seasonal Interest Fall": "yellow flowers",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Pivetz, 2001",
    "Text Title": "Fitoremediācijas izmantošanas iespējas Latvijā, 2011, Valujeva, Grīnfelde and Straupe",
    "Article Link": "",
    "Book Link": "http://www.murzl.llu.lv/?p=528",
    "Plant Genus": "Salix",
    "Plant Species": "schwerinii x viminalis",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "phytovolatilzation, phytodegradation and Rhizodegradation",
    "Contaminant Type": "petroleum",
    "Contaminant": "mazuts",
    "Contaminant Abbreviation": "Aliphatic petrochemicals",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "tree",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "MWe",
    "Shade": "N",
    "Height": "6 m",
    "Growth Rate": "F",
    "US Hardiness Zone": "4 - 8",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "flowers",
    "Seasonal Interest Summer": "seeds",
    "Seasonal Interest Fall": "foliage",
    "Seasonal Interest Winter": "from",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Applied Natural Sciences, Inc., 2997\nBarac et al., 2009\nBurken and Schnoor, 1997a\nColtrain,2004\nCook et al., 2010\nCook and Hesterberg, 2012\nEl-Gendy et al., 2009\nEuliss et al., 2008\nFagiolo and Ferro, 2004\nFerro et al., 2013\nFerro, 2006\nITRC PHYTO 3\nKulakow, 2006b\nKulakow, 2006\nLuce, 2006\nMa et al., 2004\nOlderbak and Erickson, 2004\nPalmroth et al., 2006\nSpriggs et al., 2005\nTossell, 2006\nUnterbrunner et al., 2007\nWeishaar et al., 2009\nWiddowson et al., 2005",
    "Text Title": "Phyto, 2015 Kennen and Kirkwood",
    "Article Link": "",
    "Book Link": "https://www.taylorfrancis.com/books/mono/10.4324/9781315746661/phyto-niall-kirkwood-kate-kennen",
    "Plant Genus": "Populus",
    "Plant Species": "spp.",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "",
    "Contaminant Type": "petroleum",
    "Contaminant": "petroleum",
    "Contaminant Abbreviation": "Aniline",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "tree",
    "Soil Ph": "",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": "varies",
    "Growth Rate": "F",
    "US Hardiness Zone": "0 - 10",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "flowers",
    "Seasonal Interest Summer": "",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Applied Natural Sciences, Inc., 2997\nBarac et al., 2009\nBurken and Schnoor, 1997a\nColtrain,2004\nCook et al., 2010\nCook and Hesterberg, 2012\nEl-Gendy et al., 2009\nEuliss et al., 2008\nFagiolo and Ferro, 2004\nFerro et al., 2013\nFerro, 2006\nITRC PHYTO 3\nKulakow, 2006b\nKulakow, 2006\nLuce, 2006\nMa et al., 2004\nOlderbak and Erickson, 2004\nPalmroth et al., 2006\nSpriggs et al., 2005\nTossell, 2006\nUnterbrunner et al., 2007\nWeishaar et al., 2009\nWiddowson et al., 2005",
    "Text Title": "Phyto, 2015 Kennen and Kirkwood",
    "Article Link": "",
    "Book Link": "https://www.taylorfrancis.com/books/mono/10.4324/9781315746661/phyto-niall-kirkwood-kate-kennen",
    "Plant Genus": "Populus",
    "Plant Species": "spp.",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "",
    "Contaminant Type": "petroleum",
    "Contaminant": "petroleum",
    "Contaminant Abbreviation": "Benzene",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "tree",
    "Soil Ph": "",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": "varies",
    "Growth Rate": "F",
    "US Hardiness Zone": "0 - 10",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "flowers",
    "Seasonal Interest Summer": "",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Applied Natural Sciences, Inc., 2997\nBarac et al., 2009\nBurken and Schnoor, 1997a\nColtrain,2004\nCook et al., 2010\nCook and Hesterberg, 2012\nEl-Gendy et al., 2009\nEuliss et al., 2008\nFagiolo and Ferro, 2004\nFerro et al., 2013\nFerro, 2006\nITRC PHYTO 3\nKulakow, 2006b\nKulakow, 2006\nLuce, 2006\nMa et al., 2004\nOlderbak and Erickson, 2004\nPalmroth et al., 2006\nSpriggs et al., 2005\nTossell, 2006\nUnterbrunner et al., 2007\nWeishaar et al., 2009\nWiddowson et al., 2005",
    "Text Title": "Phyto, 2015 Kennen and Kirkwood",
    "Article Link": "",
    "Book Link": "https://www.taylorfrancis.com/books/mono/10.4324/9781315746661/phyto-niall-kirkwood-kate-kennen",
    "Plant Genus": "Populus",
    "Plant Species": "spp.",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "",
    "Contaminant Type": "petroleum",
    "Contaminant": "petroleum",
    "Contaminant Abbreviation": "Ethylbenzene",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "tree",
    "Soil Ph": "",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": "varies",
    "Growth Rate": "F",
    "US Hardiness Zone": "0 - 10",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "flowers",
    "Seasonal Interest Summer": "",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Applied Natural Sciences, Inc., 2997\nBarac et al., 2009\nBurken and Schnoor, 1997a\nColtrain,2004\nCook et al., 2010\nCook and Hesterberg, 2012\nEl-Gendy et al., 2009\nEuliss et al., 2008\nFagiolo and Ferro, 2004\nFerro et al., 2013\nFerro, 2006\nITRC PHYTO 3\nKulakow, 2006b\nKulakow, 2006\nLuce, 2006\nMa et al., 2004\nOlderbak and Erickson, 2004\nPalmroth et al., 2006\nSpriggs et al., 2005\nTossell, 2006\nUnterbrunner et al., 2007\nWeishaar et al., 2009\nWiddowson et al., 2005",
    "Text Title": "Phyto, 2015 Kennen and Kirkwood",
    "Article Link": "",
    "Book Link": "https://www.taylorfrancis.com/books/mono/10.4324/9781315746661/phyto-niall-kirkwood-kate-kennen",
    "Plant Genus": "Populus",
    "Plant Species": "spp.",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "",
    "Contaminant Type": "petroleum",
    "Contaminant": "petroleum",
    "Contaminant Abbreviation": "Phenol",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "tree",
    "Soil Ph": "",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": "varies",
    "Growth Rate": "F",
    "US Hardiness Zone": "0 - 10",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "flowers",
    "Seasonal Interest Summer": "",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Applied Natural Sciences, Inc., 2997\nBarac et al., 2009\nBurken and Schnoor, 1997a\nColtrain,2004\nCook et al., 2010\nCook and Hesterberg, 2012\nEl-Gendy et al., 2009\nEuliss et al., 2008\nFagiolo and Ferro, 2004\nFerro et al., 2013\nFerro, 2006\nITRC PHYTO 3\nKulakow, 2006b\nKulakow, 2006\nLuce, 2006\nMa et al., 2004\nOlderbak and Erickson, 2004\nPalmroth et al., 2006\nSpriggs et al., 2005\nTossell, 2006\nUnterbrunner et al., 2007\nWeishaar et al., 2009\nWiddowson et al., 2005",
    "Text Title": "Phyto, 2015 Kennen and Kirkwood",
    "Article Link": "",
    "Book Link": "https://www.taylorfrancis.com/books/mono/10.4324/9781315746661/phyto-niall-kirkwood-kate-kennen",
    "Plant Genus": "Populus",
    "Plant Species": "spp.",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "",
    "Contaminant Type": "petroleum",
    "Contaminant": "petroleum",
    "Contaminant Abbreviation": "Toluene",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "tree",
    "Soil Ph": "",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": "varies",
    "Growth Rate": "F",
    "US Hardiness Zone": "0 - 10",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "flowers",
    "Seasonal Interest Summer": "",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Applied Natural Sciences, Inc., 2997\nBarac et al., 2009\nBurken and Schnoor, 1997a\nColtrain,2004\nCook et al., 2010\nCook and Hesterberg, 2012\nEl-Gendy et al., 2009\nEuliss et al., 2008\nFagiolo and Ferro, 2004\nFerro et al., 2013\nFerro, 2006\nITRC PHYTO 3\nKulakow, 2006b\nKulakow, 2006\nLuce, 2006\nMa et al., 2004\nOlderbak and Erickson, 2004\nPalmroth et al., 2006\nSpriggs et al., 2005\nTossell, 2006\nUnterbrunner et al., 2007\nWeishaar et al., 2009\nWiddowson et al., 2005",
    "Text Title": "Phyto, 2015 Kennen and Kirkwood",
    "Article Link": "",
    "Book Link": "https://www.taylorfrancis.com/books/mono/10.4324/9781315746661/phyto-niall-kirkwood-kate-kennen",
    "Plant Genus": "Populus",
    "Plant Species": "spp.",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "",
    "Contaminant Type": "petroleum",
    "Contaminant": "petroleum",
    "Contaminant Abbreviation": "m-Xylene",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "tree",
    "Soil Ph": "",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": "varies",
    "Growth Rate": "F",
    "US Hardiness Zone": "0 - 10",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "flowers",
    "Seasonal Interest Summer": "",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Applied Natural Sciences, Inc., 2997\nBarac et al., 2009\nBurken and Schnoor, 1997a\nColtrain,2004\nCook et al., 2010\nCook and Hesterberg, 2012\nEl-Gendy et al., 2009\nEuliss et al., 2008\nFagiolo and Ferro, 2004\nFerro et al., 2013\nFerro, 2006\nITRC PHYTO 3\nKulakow, 2006b\nKulakow, 2006\nLuce, 2006\nMa et al., 2004\nOlderbak and Erickson, 2004\nPalmroth et al., 2006\nSpriggs et al., 2005\nTossell, 2006\nUnterbrunner et al., 2007\nWeishaar et al., 2009\nWiddowson et al., 2005",
    "Text Title": "Phyto, 2015 Kennen and Kirkwood",
    "Article Link": "",
    "Book Link": "https://www.taylorfrancis.com/books/mono/10.4324/9781315746661/phyto-niall-kirkwood-kate-kennen",
    "Plant Genus": "Populus",
    "Plant Species": "spp.",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "rhizodegradation and phytodegradation",
    "Contaminant Type": "petroleum",
    "Contaminant": "petroleum",
    "Contaminant Abbreviation": "PAH",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "tree",
    "Soil Ph": "",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": "varies",
    "Growth Rate": "F",
    "US Hardiness Zone": "0 - 10",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "flowers",
    "Seasonal Interest Summer": "",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Applied Natural Sciences, Inc., 2997\nBarac et al., 2009\nBurken and Schnoor, 1997a\nColtrain,2004\nCook et al., 2010\nCook and Hesterberg, 2012\nEl-Gendy et al., 2009\nEuliss et al., 2008\nFagiolo and Ferro, 2004\nFerro et al., 2013\nFerro, 2006\nITRC PHYTO 3\nKulakow, 2006b\nKulakow, 2006\nLuce, 2006\nMa et al., 2004\nOlderbak and Erickson, 2004\nPalmroth et al., 2006\nSpriggs et al., 2005\nTossell, 2006\nUnterbrunner et al., 2007\nWeishaar et al., 2009\nWiddowson et al., 2005",
    "Text Title": "Phyto, 2015 Kennen and Kirkwood",
    "Article Link": "",
    "Book Link": "https://www.taylorfrancis.com/books/mono/10.4324/9781315746661/phyto-niall-kirkwood-kate-kennen",
    "Plant Genus": "Populus",
    "Plant Species": "spp.",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "rhizodegradation, phytodegradation and phytovolatilization",
    "Contaminant Type": "petroleum",
    "Contaminant": "petroleum",
    "Contaminant Abbreviation": "BTEX",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "tree",
    "Soil Ph": "",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": "varies",
    "Growth Rate": "F",
    "US Hardiness Zone": "0 - 10",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "flowers",
    "Seasonal Interest Summer": "",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Applied Natural Sciences, Inc., 2997\nBarac et al., 2009\nBurken and Schnoor, 1997a\nColtrain,2004\nCook et al., 2010\nCook and Hesterberg, 2012\nEl-Gendy et al., 2009\nEuliss et al., 2008\nFagiolo and Ferro, 2004\nFerro et al., 2013\nFerro, 2006\nITRC PHYTO 3\nKulakow, 2006b\nKulakow, 2006\nLuce, 2006\nMa et al., 2004\nOlderbak and Erickson, 2004\nPalmroth et al., 2006\nSpriggs et al., 2005\nTossell, 2006\nUnterbrunner et al., 2007\nWeishaar et al., 2009\nWiddowson et al., 2005",
    "Text Title": "Phyto, 2015 Kennen and Kirkwood",
    "Article Link": "",
    "Book Link": "https://www.taylorfrancis.com/books/mono/10.4324/9781315746661/phyto-niall-kirkwood-kate-kennen",
    "Plant Genus": "Populus",
    "Plant Species": "spp.",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "phytodegradation and phytovolatilization",
    "Contaminant Type": "petroleum",
    "Contaminant": "petroleum",
    "Contaminant Abbreviation": "MTBE",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "tree",
    "Soil Ph": "",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": "varies",
    "Growth Rate": "F",
    "US Hardiness Zone": "0 - 10",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "flowers",
    "Seasonal Interest Summer": "",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Applied Natural Sciences, Inc., 2997\nBarac et al., 2009\nBurken and Schnoor, 1997a\nColtrain,2004\nCook et al., 2010\nCook and Hesterberg, 2012\nEl-Gendy et al., 2009\nEuliss et al., 2008\nFagiolo and Ferro, 2004\nFerro et al., 2013\nFerro, 2006\nITRC PHYTO 3\nKulakow, 2006b\nKulakow, 2006\nLuce, 2006\nMa et al., 2004\nOlderbak and Erickson, 2004\nPalmroth et al., 2006\nSpriggs et al., 2005\nTossell, 2006\nUnterbrunner et al., 2007\nWeishaar et al., 2009\nWiddowson et al., 2005",
    "Text Title": "Phyto, 2015 Kennen and Kirkwood",
    "Article Link": "",
    "Book Link": "https://www.taylorfrancis.com/books/mono/10.4324/9781315746661/phyto-niall-kirkwood-kate-kennen",
    "Plant Genus": "Populus",
    "Plant Species": "spp.",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "",
    "Contaminant Type": "petroleum",
    "Contaminant": "petroleum",
    "Contaminant Abbreviation": "DRO",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "tree",
    "Soil Ph": "",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": "varies",
    "Growth Rate": "F",
    "US Hardiness Zone": "0 - 10",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "flowers",
    "Seasonal Interest Summer": "",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Applied Natural Sciences, Inc., 2997\nBarac et al., 2009\nBurken and Schnoor, 1997a\nColtrain,2004\nCook et al., 2010\nCook and Hesterberg, 2012\nEl-Gendy et al., 2009\nEuliss et al., 2008\nFagiolo and Ferro, 2004\nFerro et al., 2013\nFerro, 2006\nITRC PHYTO 3\nKulakow, 2006b\nKulakow, 2006\nLuce, 2006\nMa et al., 2004\nOlderbak and Erickson, 2004\nPalmroth et al., 2006\nSpriggs et al., 2005\nTossell, 2006\nUnterbrunner et al., 2007\nWeishaar et al., 2009\nWiddowson et al., 2005",
    "Text Title": "Phyto, 2015 Kennen and Kirkwood",
    "Article Link": "",
    "Book Link": "https://www.taylorfrancis.com/books/mono/10.4324/9781315746661/phyto-niall-kirkwood-kate-kennen",
    "Plant Genus": "Populus",
    "Plant Species": "spp.",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "",
    "Contaminant Type": "petroleum",
    "Contaminant": "petroleum",
    "Contaminant Abbreviation": "TPH",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "tree",
    "Soil Ph": "",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": "varies",
    "Growth Rate": "F",
    "US Hardiness Zone": "0 - 10",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "flowers",
    "Seasonal Interest Summer": "",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Banks and Schwab, 1998 Cook & Hesterberg, 2012, Flathman and Lanza, 2998, ITRC PHYTO 3, Johnson et al., 2005, Kulakow, 2006c",
    "Text Title": "Phyto, 2015 Kennen and Kirkwood",
    "Article Link": "",
    "Book Link": "https://www.taylorfrancis.com/books/mono/10.4324/9781315746661/phyto-niall-kirkwood-kate-kennen",
    "Plant Genus": "Trifolium",
    "Plant Species": "rapens",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "",
    "Contaminant Type": "petroleum",
    "Contaminant": "petroleum",
    "Contaminant Abbreviation": "Fluoranthene",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils and can grow in very alkaline soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": ".1 m",
    "Growth Rate": "M",
    "US Hardiness Zone": "4 - 8",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "white flowers and ground cover",
    "Seasonal Interest Fall": "ground cover",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Banks and Schwab, 1998 Cook & Hesterberg, 2012, Flathman and Lanza, 2998, ITRC PHYTO 3, Johnson et al., 2005, Kulakow, 2006c",
    "Text Title": "Phyto, 2015 Kennen and Kirkwood",
    "Article Link": "",
    "Book Link": "https://www.taylorfrancis.com/books/mono/10.4324/9781315746661/phyto-niall-kirkwood-kate-kennen",
    "Plant Genus": "Trifolium",
    "Plant Species": "rapens",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "",
    "Contaminant Type": "petroleum",
    "Contaminant": "petroleum",
    "Contaminant Abbreviation": "Phenanthrene",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils and can grow in very alkaline soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": ".1 m",
    "Growth Rate": "M",
    "US Hardiness Zone": "4 - 8",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "white flowers and ground cover",
    "Seasonal Interest Fall": "ground cover",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Banks and Schwab, 1998 Cook & Hesterberg, 2012, Flathman and Lanza, 2998, ITRC PHYTO 3, Johnson et al., 2005, Kulakow, 2006c",
    "Text Title": "Phyto, 2015 Kennen and Kirkwood",
    "Article Link": "",
    "Book Link": "https://www.taylorfrancis.com/books/mono/10.4324/9781315746661/phyto-niall-kirkwood-kate-kennen",
    "Plant Genus": "Trifolium",
    "Plant Species": "rapens",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "",
    "Contaminant Type": "petroleum",
    "Contaminant": "petroleum",
    "Contaminant Abbreviation": "Pyrene",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils and can grow in very alkaline soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": ".1 m",
    "Growth Rate": "M",
    "US Hardiness Zone": "4 - 8",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "white flowers and ground cover",
    "Seasonal Interest Fall": "ground cover",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Banks and Schwab, 1998 Cook & Hesterberg, 2012, Flathman and Lanza, 2998, ITRC PHYTO 3, Johnson et al., 2005, Kulakow, 2006c",
    "Text Title": "Phyto, 2015 Kennen and Kirkwood",
    "Article Link": "",
    "Book Link": "https://www.taylorfrancis.com/books/mono/10.4324/9781315746661/phyto-niall-kirkwood-kate-kennen",
    "Plant Genus": "Trifolium",
    "Plant Species": "rapens",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "",
    "Contaminant Type": "petroleum",
    "Contaminant": "petroleum",
    "Contaminant Abbreviation": "TPH",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils and can grow in very alkaline soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": ".1 m",
    "Growth Rate": "M",
    "US Hardiness Zone": "4 - 8",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "white flowers and ground cover",
    "Seasonal Interest Fall": "ground cover",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Banks and Schwab, 1998 Cook & Hesterberg, 2012, Flathman and Lanza, 2998, ITRC PHYTO 3, Johnson et al., 2005, Kulakow, 2006c",
    "Text Title": "Fitoremediācijas izmantošanas iespējas Latvijā, 2011, Valujeva, Grīnfelde and Straupe",
    "Article Link": "",
    "Book Link": "https://www.taylorfrancis.com/books/mono/10.4324/9781315746661/phyto-niall-kirkwood-kate-kennen",
    "Plant Genus": "Trifolium",
    "Plant Species": "rapens",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "organic",
    "Phyto Process": "rhizodegradation and phytodegradation",
    "Contaminant Type": "petroleum",
    "Contaminant": "petroleum",
    "Contaminant Abbreviation": "PAH",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils and can grow in very alkaline soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": ".1 m",
    "Growth Rate": "M",
    "US Hardiness Zone": "4 - 8",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "white flowers and ground cover",
    "Seasonal Interest Fall": "ground cover",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Kadlec, Knight, 1996; EPA, 2004",
    "Text Title": "Fitoremediācijas izmantošanas iespējas Latvijā, 2011, Valujeva, Grīnfelde and Straupe",
    "Article Link": "",
    "Book Link": "http://www.murzl.llu.lv/?p=528",
    "Plant Genus": "Typha",
    "Plant Species": "latifolia",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction and phytostabaliztion",
    "Contaminant Type": "heavy metal",
    "Contaminant": "arsenic",
    "Contaminant Abbreviation": "As",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "herbaceous",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "WeWa",
    "Shade": "N",
    "Height": "1.2-2.2 m",
    "Growth Rate": "F",
    "US Hardiness Zone": "3 - 10",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "brown bloom",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Kadlec, Knight, 1996; EPA, 2004",
    "Text Title": "Fitoremediācijas izmantošanas iespējas Latvijā, 2011, Valujeva, Grīnfelde and Straupe",
    "Article Link": "",
    "Book Link": "http://www.murzl.llu.lv/?p=528",
    "Plant Genus": "Typha",
    "Plant Species": "latifolia",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "lead",
    "Contaminant Abbreviation": "Pb",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "herbaceous",
    "Soil Ph": "",
    "Soil type": "",
    "Moisture": "",
    "Shade": "",
    "Height": "1.2-2.2 m",
    "Growth Rate": "",
    "US Hardiness Zone": "",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Kadlec, Knight, 1996; EPA, 2004",
    "Text Title": "Fitoremediācijas izmantošanas iespējas Latvijā, 2011, Valujeva, Grīnfelde and Straupe",
    "Article Link": "",
    "Book Link": "http://www.murzl.llu.lv/?p=528",
    "Plant Genus": "Typha",
    "Plant Species": "latifolia",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytometabolism and phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "copper",
    "Contaminant Abbreviation": "Cu",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "herbaceous",
    "Soil Ph": "",
    "Soil type": "",
    "Moisture": "",
    "Shade": "",
    "Height": "1.2-2.2 m",
    "Growth Rate": "",
    "US Hardiness Zone": "",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Kadlec, Knight, 1996; EPA, 2004",
    "Text Title": "Fitoremediācijas izmantošanas iespējas Latvijā, 2011, Valujeva, Grīnfelde and Straupe",
    "Article Link": "",
    "Book Link": "http://www.murzl.llu.lv/?p=528",
    "Plant Genus": "Typha",
    "Plant Species": "latifolia",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "manganese",
    "Contaminant Abbreviation": "Mn",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "herbaceous",
    "Soil Ph": "",
    "Soil type": "",
    "Moisture": "",
    "Shade": "",
    "Height": "1.2-2.2 m",
    "Growth Rate": "",
    "US Hardiness Zone": "",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "Kadlec, Knight, 1996; EPA, 2004",
    "Text Title": "Fitoremediācijas izmantošanas iespējas Latvijā, 2011, Valujeva, Grīnfelde and Straupe",
    "Article Link": "",
    "Book Link": "http://www.murzl.llu.lv/?p=528",
    "Plant Genus": "Typha",
    "Plant Species": "latifolia",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "chromium",
    "Contaminant Abbreviation": "Cr",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "herbaceous",
    "Soil Ph": "",
    "Soil type": "",
    "Moisture": "",
    "Shade": "",
    "Height": "1.2-2.2 m",
    "Growth Rate": "",
    "US Hardiness Zone": "",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "McCutcheon, Schnoor, 2003",
    "Text Title": "Fitoremediācijas izmantošanas iespējas Latvijā, 2011, Valujeva, Grīnfelde and Straupe",
    "Article Link": "",
    "Book Link": "http://www.murzl.llu.lv/?p=528",
    "Plant Genus": "Lolium",
    "Plant Species": "multiflorum",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytostabilization",
    "Contaminant Type": "radio-nuclides",
    "Contaminant": "cesium",
    "Contaminant Abbreviation": "Cs",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "grasses and sedges",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": ".3 m",
    "Growth Rate": "F",
    "US Hardiness Zone": "4 - 8",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "bloom",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "McCutcheon, Schnoor, 2003",
    "Text Title": "Fitoremediācijas izmantošanas iespējas Latvijā, 2011, Valujeva, Grīnfelde and Straupe",
    "Article Link": "",
    "Book Link": "http://www.murzl.llu.lv/?p=528",
    "Plant Genus": "Lolium",
    "Plant Species": "multiflorum",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "chromium",
    "Contaminant Abbreviation": "Cr",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "grasses and sedges",
    "Soil Ph": "mildly acid, neutral and basic (mildly alkaline) soils",
    "Soil type": "LMH",
    "Moisture": "M",
    "Shade": "N",
    "Height": ".3 m",
    "Growth Rate": "F",
    "US Hardiness Zone": "4 - 8",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "bloom",
    "Seasonal Interest Summer": "bloom",
    "Seasonal Interest Fall": "",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "McCutcheon, Schnoor, 2003",
    "Text Title": "Fitoremediācijas izmantošanas iespējas Latvijā, 2011, Valujeva, Grīnfelde and Straupe",
    "Article Link": "",
    "Book Link": "http://www.murzl.llu.lv/?p=528",
    "Plant Genus": "Sorghum",
    "Plant Species": "halepense",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "aluminum",
    "Contaminant Abbreviation": "Al",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "grasses and sedges",
    "Soil Ph": "",
    "Soil type": "LMH",
    "Moisture": "DM",
    "Shade": "N",
    "Height": "2 m",
    "Growth Rate": "F",
    "US Hardiness Zone": "7 -10",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "bloom",
    "Seasonal Interest Fall": "bloom",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "McCutcheon, Schnoor, 2003",
    "Text Title": "Fitoremediācijas izmantošanas iespējas Latvijā, 2011, Valujeva, Grīnfelde and Straupe",
    "Article Link": "",
    "Book Link": "http://www.murzl.llu.lv/?p=528",
    "Plant Genus": "Sorghum",
    "Plant Species": "halepense",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction and phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "arsenic",
    "Contaminant Abbreviation": "As",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "grasses and sedges",
    "Soil Ph": "",
    "Soil type": "LMH",
    "Moisture": "DM",
    "Shade": "N",
    "Height": "3 m",
    "Growth Rate": "F",
    "US Hardiness Zone": "8 -10",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "bloom",
    "Seasonal Interest Fall": "bloom",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "McCutcheon, Schnoor, 2003",
    "Text Title": "Fitoremediācijas izmantošanas iespējas Latvijā, 2011, Valujeva, Grīnfelde and Straupe",
    "Article Link": "",
    "Book Link": "http://www.murzl.llu.lv/?p=528",
    "Plant Genus": "Sorghum",
    "Plant Species": "halepense",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytostabilization",
    "Contaminant Type": "radio-nuclides",
    "Contaminant": "cesium",
    "Contaminant Abbreviation": "Cs",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "grasses and sedges",
    "Soil Ph": "",
    "Soil type": "LMH",
    "Moisture": "DM",
    "Shade": "N",
    "Height": "4 m",
    "Growth Rate": "F",
    "US Hardiness Zone": "9 -10",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "bloom",
    "Seasonal Interest Fall": "bloom",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "McCutcheon, Schnoor, 2003",
    "Text Title": "Fitoremediācijas izmantošanas iespējas Latvijā, 2011, Valujeva, Grīnfelde and Straupe",
    "Article Link": "",
    "Book Link": "http://www.murzl.llu.lv/?p=528",
    "Plant Genus": "Sorghum",
    "Plant Species": "halepense",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytometabolism and phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "copper",
    "Contaminant Abbreviation": "Cu",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "grasses and sedges",
    "Soil Ph": "",
    "Soil type": "LMH",
    "Moisture": "DM",
    "Shade": "N",
    "Height": "5 m",
    "Growth Rate": "F",
    "US Hardiness Zone": "10 -10",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "bloom",
    "Seasonal Interest Fall": "bloom",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "McCutcheon, Schnoor, 2003",
    "Text Title": "Fitoremediācijas izmantošanas iespējas Latvijā, 2011, Valujeva, Grīnfelde and Straupe",
    "Article Link": "",
    "Book Link": "http://www.murzl.llu.lv/?p=528",
    "Plant Genus": "Sorghum",
    "Plant Species": "halepense",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytostabilization",
    "Contaminant Type": "heavy metal",
    "Contaminant": "manganese",
    "Contaminant Abbreviation": "Mn",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "grasses and sedges",
    "Soil Ph": "",
    "Soil type": "LMH",
    "Moisture": "DM",
    "Shade": "N",
    "Height": "6 m",
    "Growth Rate": "F",
    "US Hardiness Zone": "11 -10",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "bloom",
    "Seasonal Interest Fall": "bloom",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "McCutcheon, Schnoor, 2003",
    "Text Title": "Fitoremediācijas izmantošanas iespējas Latvijā, 2011, Valujeva, Grīnfelde and Straupe",
    "Article Link": "",
    "Book Link": "http://www.murzl.llu.lv/?p=528",
    "Plant Genus": "Sorghum",
    "Plant Species": "halepense",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytostabalization, phytometabolism and phytoextraction",
    "Contaminant Type": "heavy metal",
    "Contaminant": "nickel",
    "Contaminant Abbreviation": "Ni",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "grasses and sedges",
    "Soil Ph": "",
    "Soil type": "LMH",
    "Moisture": "DM",
    "Shade": "N",
    "Height": "7 m",
    "Growth Rate": "F",
    "US Hardiness Zone": "12 -10",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "bloom",
    "Seasonal Interest Fall": "bloom",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "McCutcheon, Schnoor, 2003",
    "Text Title": "Fitoremediācijas izmantošanas iespējas Latvijā, 2011, Valujeva, Grīnfelde and Straupe",
    "Article Link": "",
    "Book Link": "http://www.murzl.llu.lv/?p=528",
    "Plant Genus": "Sorghum",
    "Plant Species": "halepense",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytostabilization",
    "Contaminant Type": "radio-nuclides",
    "Contaminant": "uranium",
    "Contaminant Abbreviation": "U",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "grasses and sedges",
    "Soil Ph": "",
    "Soil type": "LMH",
    "Moisture": "DM",
    "Shade": "N",
    "Height": "8 m",
    "Growth Rate": "F",
    "US Hardiness Zone": "13 -10",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "bloom",
    "Seasonal Interest Fall": "bloom",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  },
  {
    "Reference": "McCutcheon, Schnoor, 2003",
    "Text Title": "Fitoremediācijas izmantošanas iespējas Latvijā, 2011, Valujeva, Grīnfelde and Straupe",
    "Article Link": "",
    "Book Link": "http://www.murzl.llu.lv/?p=528",
    "Plant Genus": "Sorghum",
    "Plant Species": "halepense",
    "Plant Cultivar": "",
    "Plant Tissue": "",
    "Organic or Inorganic": "inorganic",
    "Phyto Process": "phytoextraction, phytostabilization and phytometabolism",
    "Contaminant Type": "heavy metal",
    "Contaminant": "zinc",
    "Contaminant Abbreviation": "Zn",
    "Removal Rate": "",
    "Removal Rate Units": "",
    "Timeline": "",
    "Notes": "",
    "Plant Category": "grasses and sedges",
    "Soil Ph": "",
    "Soil type": "LMH",
    "Moisture": "DM",
    "Shade": "N",
    "Height": "9 m",
    "Growth Rate": "F",
    "US Hardiness Zone": "14 -10",
    "Plant Image": "",
    "Contaminant Image": "",
    "Geography": "",
    "Seasonal Interest Spring": "",
    "Seasonal Interest Summer": "bloom",
    "Seasonal Interest Fall": "bloom",
    "Seasonal Interest Winter": "",
    "source_file": "PHYTO-MATTER for DevelopersV2 - Sheet2.csv"
  }
];